import { AxiosError } from "axios";
import axiosClient from "./axiosClient";

const errorHandler = (error: unknown) => {
  const err = error as AxiosError;
  return { message: err.response?.data, status: err.code };
};
export type ApiResponse = { message?: any; status?: string; data?: any };

export async function getData<T>(routeName: string, body = {}): Promise<ApiResponse> {
  try {
    const { data, status } = await axiosClient.get<T>(`/${routeName}`, body);
    return { data, status: status.toString() };
  } catch (error) {
    return errorHandler(error);
  }
}

export type PostData<T> = ReturnType<typeof postData<T>>;

export async function postData<T>(routeName: string, body = {}) {
  try {
    const { data, status } = await axiosClient.post<T>(`/${routeName}`, body);
    return { data, status: status.toString() };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function putData<T>(routeName: string, body = {}) {
  try {
    const { data, status } = await axiosClient.put(`/${routeName}`, body);
    return { data, status: status.toString() };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function patchData<T>(routeName: string, body = {}) {
  try {
    const res = await axiosClient.patch<T>(`/${routeName}`, body);
    const { data, status } = res;
    return { data, status: status.toString() };
  } catch (error) {
    return errorHandler(error);
  }
}

export async function deleteData<T>(routeName: string, body = {}) {
  try {
    const { data, status } = await axiosClient.delete<T>(`/${routeName}`, body);
    return { data, status: status.toString() };
  } catch (error) {
    return errorHandler(error);
  }
}
