import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";
import { PointsSummary } from "../types";

export function usePointList() {
  const {
    data: points,
    mutate: mutatePoints,
    error: errorPoints,
    isValidating: isValidatingPoints
  } = useSWRImmutable<PointsSummary>(`/Points/GetUserPoints`);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [points]);

  return { points, mutatePoints, errorPoints, isValidatingPoints };
}

export default usePointList;
