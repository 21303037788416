import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";
import { FleetAvailableShips } from "../types";

export function useFleetEdit(planetId: number, fleetId: number) {
  const {
    data: fleetShips,
    mutate: mutateFleetShips,
    error: errorFleetShips,
    isValidating: isValidatingFleetShips
  } = useSWRImmutable<FleetAvailableShips[]>(
    planetId && fleetId ? `/Fleet/GetAvailableShipsForEdit/${planetId}/${fleetId}` : null
  );

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [fleetShips]);

  return { fleetShips, mutateFleetShips, errorFleetShips, isValidatingFleetShips };
}

export default useFleetEdit;
