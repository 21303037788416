import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";
import { UserBonus } from "../types";

export function useUserBonus(planetId: number) {
  const {
    data: userBonus,
    mutate: mutateUserBonusDesc,
    error: errorUserBonus,
    isValidating: isValidatingUserBonus
  } = useSWRImmutable<UserBonus[]>(planetId ? `/Bonus/GetUserBonuses/${planetId}` : null);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [userBonus]);

  return { userBonus, mutateUserBonusDesc, errorUserBonus, isValidatingUserBonus };
}

export default useUserBonus;
