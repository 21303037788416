import { z } from "zod";

export const PointsSummary = z.object({
  buildingsPoints: z.number(),
  technologiesPoints: z.number(),
  militaryPoints: z.number(),
  totalPoints: z.number()
});

export type PointsSummary = z.infer<typeof PointsSummary>;

export const RankingUser = PointsSummary.extend({ position: z.number() });

export type RankingUser = z.infer<typeof RankingUser>;

export const RankingUserItem = z.object({ userName: z.string(), totalPoints: z.number(), position: z.number() });

export type RankingUserItem = z.infer<typeof RankingUserItem>;

export const RankingUserAll = z.object({
  rankingUser: RankingUser,
  rankingMain: z.array(RankingUserItem)
});

export type RankingUserAll = z.infer<typeof RankingUserAll>;
