import { useEffect } from "react";
import useSWR from "swr";
import { DashboardPlanet } from "../types";

export function useGameDashboard({ redirectTo = "", redirectIfFound = false } = {}) {
  const {
    data: gameDashboard,
    mutate: mutateGameDashboard,
    error: errorGameDashboard,
    isValidating: isValidatingGameDashboard
  } = useSWR<DashboardPlanet>(`/Game/Dashboard`, { refreshInterval: 10000 });

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [gameDashboard, redirectIfFound, redirectTo]);

  return { gameDashboard, mutateGameDashboard, errorGameDashboard, isValidatingGameDashboard };
}

export default useGameDashboard;
