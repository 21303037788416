import { useEffect } from "react";
import useSWR from "swr";
import { RankingUserAll } from "../types";

export function useUserRanking() {
  const {
    data: rankingTop,
    mutate: mutateUserRanking,
    error: errorUserRanking,
    isValidating: isValidatingUserRanking,
    isLoading
  } = useSWR<RankingUserAll>("/Points/GetUserRanking");

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [rankingTop]);

  return { rankingTop, mutateUserRanking, errorUserRanking, isValidatingUserRanking, isLoading };
}

export default useUserRanking;
