import React, { createContext, ReactElement, ReactNode } from "react";
import { RootStore, RootStoreModel } from "./RootStore";

export const StoreContext = createContext<RootStoreModel>({} as RootStoreModel);

export type StoreComponent = React.FC<{
  children: ReactNode;
}>;

export const StoreProvider: StoreComponent = ({ children }): ReactElement => {
  return <StoreContext.Provider value={new RootStore()}>{children}</StoreContext.Provider>;
};
