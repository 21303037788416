import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";
import { Fleet } from "../types";

export function useFleetsList() {
  const {
    data: fleet,
    mutate: mutateFleet,
    error: errorFleet,
    isValidating: isValidatingFleet
  } = useSWRImmutable<Fleet[]>(`/Fleet/GetFleetsList`);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [fleet]);

  return { fleet, mutateFleet, errorFleet, isValidatingFleet };
}

export default useFleetsList;
