import { useEffect } from "react";
import useSWR from "swr";
import { Galaxy } from "../types";

export function useGalaxy({ redirectTo = "", redirectIfFound = false } = {}) {
  const {
    data: galaxy,
    mutate: mutateGalaxy,
    error: errorGalaxy,
    isValidating: isValidatingGalaxy
  } = useSWR<Galaxy[]>("/Galaxy/GetGalaxies");

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [galaxy, redirectIfFound, redirectTo]);

  return { galaxy, mutateGalaxy, errorGalaxy, isValidatingGalaxy };
}

export default useGalaxy;
