import { useEffect } from "react";
import useSWR from "swr";
import { StorageList } from "../types";
export function usePlanetStorage(planetId: number) {
  const {
    data: planetStorage,
    mutate: mutatePlanetStorage,
    error: errorPlanetStorage,
    isValidating: isValidatingPlanetStorage
  } = useSWR<StorageList>(planetId ? `/Production/GetPlanetStorage/${planetId}` : null);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [planetStorage]);

  return { planetStorage, mutatePlanetStorage, errorPlanetStorage, isValidatingPlanetStorage };
}

export default usePlanetStorage;
