import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: true,
  styles: {
    global: {
      "html, body": {
        fontFamily:
          "Averta,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol"
      }
    }
  },
  colors: {
    brand: {
      50: "#fdeee8",
      100: "#faded0",
      200: "#f6bca1",
      300: "#f19b73",
      400: "#ed7944",
      500: "#e85815",
      600: "#ba4611",
      700: "#8b350d",
      800: "#5d2308",
      900: "#461a06"
    },
    lightGray: "#696c74",
    darkGray: "#51575E",
    highlightGray: "#f3f7fb",
    backgroundGray: "#f7f7f7",
    grayTextHeader: "#494949",
    grayText: "#7e7b84",
    grayHeader: "#4b4b4b",
    grayBorder: "#efefef",
    backgroundLightGray: "#fafafa"
  },
  components: {
    Button: {
      variants: {
        text: {
          color: "brand.500",
          _hover: {
            color: "brand.400"
          }
        }
      }
    }
  }
});

export default theme;
