import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";
import { UserSchema } from "../types";

export function useUserSchema(planetId: number) {
  const {
    data: userSchema,
    mutate: mutateUserSchema,
    error: errorUserSchema,
    isValidating: isValidatingUserSchema
  } = useSWRImmutable<UserSchema[]>(planetId ? `/Schema/GetUserSchemas/${planetId}` : null);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [userSchema]);

  return { userSchema, mutateUserSchema, errorUserSchema, isValidatingUserSchema };
}

export default useUserSchema;
