import { SVGProps } from "react";
import Icons from "../assets/icons";
const { AtomIcon } = Icons;

export const iconsNames = {
  atom: AtomIcon
};

export type IconName = keyof typeof iconsNames;

export interface IconProps extends SVGProps<SVGElement> {
  outlined?: boolean;
  iconName: IconName;
  padding?: number | string;
  outlineHeight?: number | string;
  outlineWidth?: number | string;
  size?: "small" | "medium" | "large";
}
