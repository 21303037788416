import { z } from "zod";
import { ActiveProgress } from "./common";

export const TechnologyProgress = ActiveProgress.extend({
  technologyId: z.number(),
  discoverStart: z.date(),
  discoverEnd: z.date()
}).omit({ buildEnd: true, buildStart: true });

export const Technology = z.object({
  id: z.number(),
  technologyId: z.number(),
  discoverTime: z.number(),
  technologyValue: z.number()
});

export type TechnologyProgress = z.infer<typeof TechnologyProgress>;

export type Technology = z.infer<typeof Technology>;
