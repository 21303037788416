import { Cost, GameSummaryProgress, PlanetResources, UserShip, postData, toast } from "@new-wars/core";
import { makeAutoObservable } from "mobx";
import { i18n } from "next-i18next";
import { KeyedMutator } from "swr";
import { CreateShipSchemaModel } from "../views/SchemaSummaryView/components/CreateShipSchema/CreateShipSchemaModel";
import { RootStoreModel } from "./RootStore";
export class ShipStore {
  public createShipSchemaModel: CreateShipSchemaModel;
  public editShipSchemaModel: CreateShipSchemaModel;
  public loading: boolean = false;

  constructor(rootStore: RootStoreModel) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.createShipSchemaModel = new CreateShipSchemaModel(rootStore);
    this.editShipSchemaModel = new CreateShipSchemaModel(rootStore);
  }

  getBuildCost = (cost: Cost, planetResources: PlanetResources, amount = 1) => {
    const { metalCost, crystalCost, orizinCost, frubinCost, frurozinCost, goldCost } = cost;
    const { metal, crystal, orizin, frubin, frurozin, gold } = planetResources;
    const totalCost = {
      metal: metalCost * amount,
      crystal: crystalCost * amount,
      orizin: orizinCost * amount,
      frubin: frubinCost * amount,
      frurozin: frurozinCost * amount,
      gold: goldCost * amount
    };
    const isMetalEnough = metal ? metal?.metalAmount >= totalCost.metal : false;
    const isCrystalEnough = crystal ? crystal?.crystalAmount >= totalCost.crystal : false;
    const isOrizinEnough = orizin ? orizin?.orizinAmount >= totalCost.orizin : false;
    const isFrubinEnough = frubin ? frubin?.frubinAmount >= totalCost.frubin : false;
    const isFrurozinEnough = frurozin ? frurozin?.frurozinAmount >= totalCost.frurozin : false;
    const isGoldEnough = gold ? gold?.goldAmount >= totalCost.gold : false;
    const isEnoughResources =
      isMetalEnough && isCrystalEnough && isOrizinEnough && isFrubinEnough && isFrurozinEnough && isGoldEnough;
    const resource = {
      gold: { isEnough: isGoldEnough, cost: totalCost.gold },
      crystal: { isEnough: isCrystalEnough, cost: totalCost.crystal },
      frubin: { isEnough: isFrubinEnough, cost: totalCost.frubin },
      frurozin: { isEnough: isFrurozinEnough, cost: totalCost.frurozin },
      metal: { isEnough: isMetalEnough, cost: totalCost.metal },
      orizin: { isEnough: isOrizinEnough, cost: totalCost.orizin }
    };

    return {
      isEnoughResources,
      resource
    };
  };

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  startShipBuild = async (schemaId: number, schemaName = "", amount = 1, planetId: number) => {
    try {
      this.setLoading(true);
      const response = await postData("Ships/StartShipBuilding", {
        schemaId,
        amount,
        planetId
      });
      this.setLoading(false);
      toast({
        title: response ? `${i18n?.t("startedBuild")}: ${schemaName}` : i18n?.t("somethingWentWrong"),
        status: response ? "success" : "error",
        isClosable: true
      });
      //@ts-ignore
      return response.data > 0;
    } catch (error) {
      console.error("startShipBuild", error);
      this.setLoading(false);
    }
  };

  cancelShipBuild = async (
    shipId: number,
    planetId: number,
    mutate: KeyedMutator<GameSummaryProgress>,
    mutateShipList: KeyedMutator<UserShip[]>
  ) => {
    try {
      this.setLoading(true);
      const response = await postData("Ships/CancelShipBuilding", {
        shipId,
        planetId
      });
      mutate();
      mutateShipList();
      this.setLoading(false);
      toast({
        title: response ? `${i18n?.t("startedBuild")}: ${name}` : i18n?.t("somethingWentWrong"),
        status: response ? "success" : "error",
        isClosable: true
      });
      console.log("cancelShipBuild", response); //@ts-ignore
      return response.data > 0;
    } catch (error) {
      console.error("cancelShipBuild", error);
      this.setLoading(false);
    }
  };
  onFinishShipBuild = async (
    shipId: number,
    planetId: number,
    mutate: KeyedMutator<GameSummaryProgress>,
    mutateShipList: KeyedMutator<UserShip[]>
  ) => {
    try {
      this.setLoading(true);
      const response = await postData("DefenseSystems/FinishShipBuilding", {
        shipId,
        planetId
      });
      mutate();
      mutateShipList();
      this.setLoading(false);
      console.log("onFinishShipBuild", response); //@ts-ignore
      return response.data > 0;
    } catch (error) {
      console.error("onFinishShipBuild", error);
      this.setLoading(false);
    }
  };
}
