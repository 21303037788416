import { useEffect } from "react";
import useSWR from "swr";
import { DefenseSystemsRecycle } from "../types";

export function useDefenseSystemsRecycle(planetId: number) {
  const {
    data: defenseSystemsRecycle,
    mutate: mutateDefenseSystemsRecycle,
    error: errorDefenseSystemsRecycle,
    isValidating: isValidatingDefenseSystemsRecycle
  } = useSWR<DefenseSystemsRecycle[]>(planetId ? `/DefenseSystems/GetDefenseSystemsRecycle/${planetId}` : null);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [defenseSystemsRecycle]);

  return {
    defenseSystemsRecycle,
    mutateDefenseSystemsRecycle,
    errorDefenseSystemsRecycle,
    isValidatingDefenseSystemsRecycle
  };
}

export default useDefenseSystemsRecycle;
