import { z } from "zod";

export const Galaxy = z.object({
  galaxyId: z.number(),
  systemCount: z.number()
});

export const DiscoveredSystemId = z.object({ systemId: z.number() });

export const DiscoveredSystem = z.object({
  galaxyId: z.number(),
  discoveredSystems: z.array(DiscoveredSystemId)
});

export const DiscoveredPlanet = z.object({
  planetId: z.number(),
  planetType: z.number(),
  planetName: z.string(),
  ownerId: z.number(),
  discovered: z.boolean()
});

export type Galaxy = z.infer<typeof Galaxy>;
export type DiscoveredSystemId = z.infer<typeof DiscoveredSystemId>;
export type DiscoveredSystem = z.infer<typeof DiscoveredSystem>;
export type DiscoveredPlanet = z.infer<typeof DiscoveredPlanet>;
