import { useEffect } from "react";
import useSWR from "swr";
import { CommunicationElementUser, CommunicationUserList } from "../types";

export function useGetCommunicationUserList(element: CommunicationElementUser) {
  const {
    data: communicationUserName,
    mutate: mutateCommunicationUserName,
    error: errorCommunicationUserName,
    isValidating: isValidatingCommunicationUserName
  } = useSWR<CommunicationUserList>(
    CommunicationElementUser.safeParse(element).success && element.userName
      ? `/GetCommunicationUserList/${element.userName}/${element.limit}`
      : null
  );

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [communicationUserName]);

  return {
    communicationUserName,
    mutateCommunicationUserName,
    errorCommunicationUserName,
    isValidatingCommunicationUserName
  };
}

export default useGetCommunicationUserList;
