import {
  DefenseSystems,
  DefenseSystemsRecycle,
  DefenseSystemsUpgrade,
  GameSummaryProgress,
  postData
} from "@new-wars/core";
import { makeAutoObservable } from "mobx";
import { KeyedMutator } from "swr";
import { RootStoreModel } from "./RootStore";

export class DefenceStore {
  private rootStore: RootStoreModel;
  loading: boolean = false;

  constructor(rootStore: RootStoreModel) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
  }

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  startDefenceSystem = async (defenseSystemId: number, amount: number, planetId: number) => {
    try {
      this.setLoading(true);

      const response = await postData("DefenseSystems/StartDefenseBuilding", {
        defenseSystemId,
        amount,
        planetId
      });
      this.setLoading(false);
      //@ts-ignore
      return response.data > 0;
    } catch (error) {
      console.error("startDefenceSystem", error);
      this.setLoading(false);
    }
  };

  cancelDefenceSystem = async (
    defenseSystemId: number,
    planetId: number,
    mutate: KeyedMutator<GameSummaryProgress>,
    mutateDefenceSystem: KeyedMutator<DefenseSystems>
  ) => {
    try {
      this.setLoading(true);
      const response = await postData("DefenseSystems/CancelDefenseBuilding", {
        defenseSystemId,
        planetId
      });
      mutate();
      mutateDefenceSystem();
      this.setLoading(false);
      console.log("cancelDefenceSystem", response); //@ts-ignore
      return response.data > 0;
    } catch (error) {
      console.error("cancelDefenceSystem", error);
      this.setLoading(false);
    }
  };

  startDefenceSystemUpgrade = async (
    defenseSystemUnitId: number,
    defenseSystemId: number,
    amount: number,
    planetId: number,
    mutate: KeyedMutator<GameSummaryProgress>,
    mutateDefenceSystem: KeyedMutator<DefenseSystemsUpgrade[]>
  ) => {
    try {
      this.setLoading(true);
      const response = await postData("DefenseSystems/StartDefenseUpgrade", {
        defenseSystemUnitId,
        defenseSystemId,
        amount,
        planetId
      });

      mutate();
      mutateDefenceSystem();
      this.setLoading(false);
      console.log("startDefenceSystemUpgrade", response); //@ts-ignore
      return response.data > 0;
    } catch (error) {
      console.error("startDefenceSystemUpgrade", error);
      this.setLoading(false);
    }
  };

  startDefenceSystemRecycle = async (
    defenseSystemUnitId: number,
    amount: number,
    planetId: number,
    mutate: KeyedMutator<GameSummaryProgress>,
    mutateDefence: KeyedMutator<DefenseSystemsRecycle[]>
  ) => {
    try {
      this.setLoading(true);

      const response = await postData("DefenseSystems/StartDefenseRecycle", {
        defenseSystemUnitId,
        amount,
        planetId
      });

      mutate();
      mutateDefence();
      this.setLoading(false);
      console.log("startDefenceSystemRecycle", response); //@ts-ignore
      return response.data > 0;
    } catch (error) {
      console.error("startDefenceSystemRecycle", error);
      this.setLoading(false);
    }
  };

  onFinishDefenceSystem = async (
    defenseSystemId: number,
    planetId: number,
    mutate: KeyedMutator<GameSummaryProgress>,
    mutateDefenceSystem: KeyedMutator<DefenseSystems>
  ) => {
    try {
      this.setLoading(true);

      const response = await postData("DefenseSystems/FinishDefenseBuilding", {
        defenseSystemId,
        planetId
      });
      mutate();
      mutateDefenceSystem();
      this.setLoading(false);
      console.log("onFinishDefenceSystem", response); //@ts-ignore
      return response.data > 0;
    } catch (error) {
      this.setLoading(false);
    }
  };
}
