import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";
import { ShipComponentsBase } from "../types/";

export function useShipComponentsBase(planetId: number) {
  const {
    data: shipComponentsBase,
    mutate: mutateShipComponentsBase,
    error: errorShipComponentsBase,
    isValidating: isValidatingShipComponentsBase
  } = useSWRImmutable<ShipComponentsBase[]>(planetId ? `/ShipComponents/GetShipComponentsBase/${planetId}` : null);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [shipComponentsBase]);

  return { shipComponentsBase, mutateShipComponentsBase, errorShipComponentsBase, isValidatingShipComponentsBase };
}

export default useShipComponentsBase;
