import { z } from "zod";
import { Progress } from "./common";
import { Cost } from "./requirement";
import { SchemaItem } from "./schema";
import { SchemaAdditionalItems } from "./ship";

export enum CountType {
  value,
  percentage
}

export const CountTypes = z.nativeEnum(CountType);

export type CountTypes = z.infer<typeof CountTypes>;

export const ShipComponentsBase = z.object({
  shipComponentId: z.number(),
  componentValue: z.number(),
  componentWeight: z.number(),
  componentEnergy: z.number(),
  countType: CountTypes,
  buildTime: z.number()
});

export enum ShipComponentCategories {
  Hull = 1,
  Drive,
  Armor,
  Armament,
  TacticalArmament,
  Cargo,
  Special
}

export const ShipComponentCategory = z.nativeEnum(ShipComponentCategories);
export type ShipComponentCategory = z.infer<typeof ShipComponentCategory>;

export enum ShipComponentsAvailable {
  Hull = 1,
  Drive,
  Armament,
  TacticalArmament
}

export const ShipComponentAvailable = z.nativeEnum(ShipComponentsAvailable);
export type ShipComponentAvailable = z.infer<typeof ShipComponentAvailable>;

export enum ShipComponentMetalCategories {
  None,
  Metal,
  Carbon,
  Covers,
  Teleport
}

export const ShipComponentMetalCategory = z.nativeEnum(ShipComponentMetalCategories);
export type ShipComponentMetalCategory = z.infer<typeof ShipComponentMetalCategory>;
export type ShipComponentsBase = z.infer<typeof ShipComponentsBase>;

export const ShipComponentDescription = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  categoryId: ShipComponentCategory,
  subCategoryId: ShipComponentMetalCategory
});

export type ShipComponentDescription = z.infer<typeof ShipComponentDescription>;

export const ShipComponentAvailableList = z.object({
  shipComponentId: z.number(),
  isValid: z.boolean()
});

export type ShipComponentAvailableList = z.infer<typeof ShipComponentAvailableList>;

export const ShipComponentAvailableOption = ShipComponentsBase.extend({
  label: z.string(),
  type: z.number(),
  categoryId: ShipComponentCategory
});

export type ShipComponentAvailableOption = z.infer<typeof ShipComponentAvailableOption>;

export const ShipComponentAvailableMultiOption = ShipComponentAvailableOption.extend({ amount: z.number() });

export type ShipComponentAvailableMultiOption = z.infer<typeof ShipComponentAvailableMultiOption>;

export const SelectedShipComponent = z.object({ shipComponentId: z.number(), amount: z.number() });

export type SelectedShipComponent = z.infer<typeof SelectedShipComponent>;

export const PostShipSchema = z.object({
  name: z.string(),
  planetId: z.number(),
  ShipComponentsList: SelectedShipComponent.array()
});

export type PostShipSchema = z.infer<typeof PostShipSchema>;

export const PostShipSchemaEdit = PostShipSchema.extend({ Id: z.number() });

export type PostShipSchemaEdit = z.infer<typeof PostShipSchemaEdit>;

export enum HullTypes {
  Light = 1,
  Medium,
  Heavy,
  Sond,
  Tactical
}

export const HullType = z.nativeEnum(HullTypes);
export type HullType = z.infer<typeof HullType>;

export const UserSchema = Cost.extend({
  id: z.number(),
  userId: z.number(),
  schemaName: z.string(),
  totalBuildTime: z.number(),
  metalCost: z.number(),
  schemaItems: SchemaItem.array(),
  schemaAdditionalItems: SchemaAdditionalItems
});

export type UserSchema = z.infer<typeof UserSchema>;

export const ShipsProgress = Progress.extend({
  cargoValue: z.number(),
  engineValue: z.number(),
  schemaId: z.number()
});

export type ShipsProgress = z.infer<typeof ShipsProgress>;
