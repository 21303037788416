import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";
import { PlanetType } from "../types";

export function usePlanetTypes({ redirectTo = "", redirectIfFound = false } = {}) {
  const {
    data: planetTypes,
    mutate: mutatePlanetTypes,
    error: errorPlanetTypes,
    isValidating: isValidatingPlanetTypes
  } = useSWRImmutable<PlanetType[]>("/PlanetTypes/GetPlanetTypes");

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [planetTypes, redirectIfFound, redirectTo]);

  return { planetTypes, mutatePlanetTypes, errorPlanetTypes, isValidatingPlanetTypes };
}

export default usePlanetTypes;
