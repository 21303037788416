import { makeObservable, observable } from "mobx";
import { signIn, SignInResponse } from "next-auth/react";
import { postData } from "../axios/api";
import { EMAIL_REGEX } from "../helpers/helpers";
import { Command } from "../mvvm/commands/Command/Command";

export class AuthorizationModel {
  email: string = "";
  password: string = "";
  username: string = "";
  logIn: Command<void, any>;
  register: Command<void, any>;

  constructor() {
    makeObservable(this, {
      email: observable,
      password: observable,
      username: observable
    });

    this.logIn = new Command(
      async () => {
        const response = (await signIn("credentials", {
          email: this.email,
          password: this.password,
          redirect: false
        })) as unknown as SignInResponse;
        if (!response.ok) {
          alert("Błędne hasło lub email.");
        }
      },
      () => {
        return EMAIL_REGEX.test(this.email) && this.password;
      }
    );

    this.register = new Command(
      async () => {
        const response = await postData("Users/Register", {
          login: this.username,
          password: this.password,
          email: this.email
        });
        if (response.status !== "200") {
          //@ts-ignore
          console.log("Error code", response.data);
          alert("Błędne dane do rejestracji");
        }
      },
      () => {
        return EMAIL_REGEX.test(this.email) && this.password && this.username.length > 3;
      }
    );
  }
}

export const authorizationModel = new AuthorizationModel();
