import { z } from "zod";
import { UserShip } from "./ship";

export const FleetAvailableShips = UserShip.extend({
  shipMass: z.number(),
  shipCurrentMass: z.number(),
  shipEnergy: z.number(),
  shipCurrentEnergy: z.number(),
  currentAmount: z.number()
});

export type FleetAvailableShips = z.infer<typeof FleetAvailableShips>;

export const Fleet = z.object({
  userId: z.number(),
  planetId: z.number(),
  fleetId: z.number(),
  fleetName: z.string(),
  totalAttack: z.number(),
  totalDefense: z.number(),
  totalCargo: z.number(),
  amount: z.number()
});

export type Fleet = z.infer<typeof Fleet>;

export const FleetItems = z.object({ shipId: z.number(), amount: z.number() });

export type FleetItems = z.infer<typeof FleetItems>;

export const FleetAddBody = z.object({
  planetId: z.number(),
  fleetName: z.string(),
  fleetItems: z.array(FleetItems)
});

export type FleetAddBody = z.infer<typeof FleetAddBody>;

export const FleetEditBody = FleetAddBody.extend({
  fleetId: z.number()
});

export type FleetEditBody = z.infer<typeof FleetEditBody>;
