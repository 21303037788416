import { useEffect } from "react";
import useSWR from "swr";
import { CommunicationElementList, CommunicationTabElement, CommunicationTabList } from "../types";

export function useCommunicationElement(element: CommunicationElementList) {
  const {
    data: communicationElement,
    mutate: mutateCommunicationElement,
    error: errorCommunicationElement,
    isValidating: isValidatingCommunicationElement
  } = useSWR<CommunicationTabList | CommunicationTabElement>(
    CommunicationElementList.safeParse(element).success
      ? `/GetCommunicationElement/${element.id}/${element.category}`
      : null
  );

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [communicationElement]);

  return {
    communicationElement,
    mutateCommunicationElement,
    errorCommunicationElement,
    isValidatingCommunicationElement
  };
}

export default useCommunicationElement;
