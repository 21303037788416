import { z } from "zod";

enum KindBonus {
  Armament = 1,
  Armor,
  Ships,
  Defence,
  Trade,
  Spy,
  Drive
}

export const BonusKind = z.nativeEnum(KindBonus);

export type BonusKind = z.infer<typeof BonusKind>;

export const BonusKinds = { 1: "armament", 2: "armor", 3: "ships", 4: "defence", 5: "trade", 6: "spy", 7: "drive" };
