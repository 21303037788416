import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";
import { ShipComponentDescription } from "../types";

export function useShipComponentsDescription({ redirectTo = "", redirectIfFound = false } = {}) {
  const {
    data: shipComponentsDesc,
    mutate: mutateShipComponentsDesc,
    error: errorShipComponentsDesc,
    isValidating: isValidatingShipComponentsDesc
  } = useSWRImmutable<ShipComponentDescription[]>("/ShipComponents/GetAllShipComponentsDescriptions");

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [shipComponentsDesc, redirectIfFound, redirectTo]);

  return { shipComponentsDesc, mutateShipComponentsDesc, errorShipComponentsDesc, isValidatingShipComponentsDesc };
}

export default useShipComponentsDescription;
