import { useEffect } from "react";
import useSWR from "swr";
import { CommunicationShortList } from "../types";

export function useCommunicationShortList(isAuthenticated: boolean) {
  const {
    data: communicationShortList,
    mutate: mutateCommunicationShortList,
    error: errorCommunicationShortList,
    isValidating: isValidatingCommunicationShortList
  } = useSWR<CommunicationShortList>(isAuthenticated ? `/GetCommunicationListShort` : null);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [communicationShortList]);

  return {
    communicationShortList,
    mutateCommunicationShortList,
    errorCommunicationShortList,
    isValidatingCommunicationShortList
  };
}

export default useCommunicationShortList;
