import { useEffect } from "react";
import useSWR from "swr";
import { DiscoveredPlanet } from "../types";

export function useDiscoveredPlanets(galaxyId: number, systemId: number) {
  const {
    data: discoveredPlanets,
    mutate: mutateDiscoveredPlanets,
    error: errorDiscoveredPlanets,
    isValidating: isValidatingDiscoveredPlanets
  } = useSWR<DiscoveredPlanet[]>(`/GetUserDiscoveredPlanets/${galaxyId}/${systemId}`);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [discoveredPlanets]);

  return {
    discoveredPlanets,
    mutateDiscoveredPlanets,
    errorDiscoveredPlanets,
    isValidatingDiscoveredPlanets
  };
}

export default useDiscoveredPlanets;
