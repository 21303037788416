import { z } from "zod";

export enum SchemaShipSResponse {
  OK = 1,
  EXISTS = -1,
  NOT_COMPONENTS = -2,
  NO_REQUIREMENTS = -3,
  NO_SPACE = -4,
  ERROR = -999
}

export const SchemaItem = z.object({ componentId: z.number(), amount: z.number() });
export type SchemaItem = z.infer<typeof SchemaItem>;
