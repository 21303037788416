import { useEffect } from "react";
import useSWR from "swr";
import { DashboardPlanets } from "../types";

export function useGameDashboardPlanets({ isLoggedIn = false, redirectTo = "", redirectIfFound = false } = {}) {
  const {
    data: gameDashboardPlanets,
    mutate: mutateGameDashboardPlanets,
    error: errorGameDashboardPlanets,
    isValidating: isValidatingGameDashboardPlanets
  } = useSWR<DashboardPlanets>(isLoggedIn ? `/Game/DashboardPlanets` : null, { refreshInterval: 10000 });

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [gameDashboardPlanets, redirectIfFound, redirectTo]);

  return {
    gameDashboardPlanets,
    mutateGameDashboardPlanets,
    errorGameDashboardPlanets,
    isValidatingGameDashboardPlanets
  };
}

export default useGameDashboardPlanets;
