import { useEffect } from "react";
import useSWR from "swr";
import { DefenseSystems } from "../types";
export function useDefenseSystems(planetId: number) {
  const {
    data: defenseSystems,
    mutate: mutateDefenseSystems,
    error: errorDefenseSystems,
    isValidating: isValidatingDefenseSystems
  } = useSWR<DefenseSystems>(planetId ? `/DefenseSystems/GetDefenseSystems/${planetId}` : null);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [defenseSystems]);

  return { defenseSystems, mutateDefenseSystems, errorDefenseSystems, isValidatingDefenseSystems };
}

export default useDefenseSystems;
