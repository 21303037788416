import { z } from "zod";

export const Description = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string()
});

export const DescriptionBuilding = Description.extend({
  buildingPnamerogressId: z.string(),
  energyCost: z.number()
});

export const DescriptionBonus = z.object({
  bonusId: z.number(),
  bonusKind: z.number(),
  bonusName: z.string(),
  bonusDescription: z.string()
});

export type Description = z.infer<typeof Description>;
export type DescriptionBuilding = z.infer<typeof DescriptionBuilding>;
export type DescriptionBonus = z.infer<typeof DescriptionBonus>;
