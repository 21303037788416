import axios from "axios";
import { signOut } from "next-auth/react";
import { isNullOrUndefined } from "../helpers";

const axiosClient = axios.create({
  timeout: 40000, // 40 s
  headers: {
    "Access-Control-Allow-Origin": "*",
    Access: "*/*"
  },
  baseURL: process.env.NEXT_PUBLIC_BACKEND_URL
});
axiosClient.defaults.baseURL = process.env.NEXT_PUBLIC_BACKEND_URL;

export const setAxiosClientAuthHeaders = (accessToken: string) => {
  !isNullOrUndefined(accessToken) && accessToken.length > 0
    ? (axiosClient.defaults.headers.common["Authorization"] = "Bearer " + accessToken)
    : delete axiosClient.defaults.headers.common["Authorization"];
};

export const setAxiosClientLanguage = (lang: string) => {
  axiosClient.defaults.headers.common["contentLanguage"] = lang ?? "pl";
};

export default axiosClient;

export const registerAxiosResponseInterceptor = () => {
  const tokenLoginAgain = async () => {
    signOut();
    setAxiosClientAuthHeaders("");
  };

  axiosClient.interceptors.response.use(
    response => response,
    async error => {
      let { response, config } = error;
      if (response?.status === 401) {
        await tokenLoginAgain();
      }
      return Promise.reject(error);
    }
  );
};
