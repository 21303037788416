import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";
import { UserShip } from "../types";

export function useShipUser(planetId: number) {
  const {
    data: shipUser,
    mutate: mutateShipUser,
    error: errorShipUser,
    isValidating: isValidatingShipUser
  } = useSWRImmutable<UserShip[]>(planetId ? `/Ships/GetUserShips/${planetId}` : null);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [shipUser]);

  return { shipUser, mutateShipUser, errorShipUser, isValidatingShipUser };
}

export default useShipUser;
