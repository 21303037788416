import { useEffect } from "react";
import useSWR from "swr";
import { BuildingsStats } from "../types";

export function useBuildingsStats(planetId: number) {
  const {
    data: buildingsStats,
    mutate: mutateBuildingsStats,
    error: errorBuildingsStats,
    isValidating: isValidatingBuildingsStats
  } = useSWR<BuildingsStats>(planetId ? `/Buildings/GetStats/${planetId}` : null);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [buildingsStats]);

  return { buildingsStats, mutateBuildingsStats, errorBuildingsStats, isValidatingBuildingsStats };
}

export default useBuildingsStats;
