import { z } from "zod";

export const CommunicationCategory = {
  MessagesFrom: 0,
  MessagesSent: 1,
  MessagesArchived: 2,
  Reports: 3
} as const;

export const CommunicationCategoryEnum = z.nativeEnum(CommunicationCategory);

export const MessageDetailsCategory = {
  Messages: 0,
  Report: 1
} as const;

export const MessageDetailsCategoryEnum = z.nativeEnum(MessageDetailsCategory);

export const CommunicationShort = z.object({ unreadCount: z.number() });

export const CommunicationListShortData = z.object({ unreadElements: z.number(), totalElements: z.number() });

export const CommunicationShortList = z.object({
  messagesReceived: CommunicationListShortData,
  messagesSent: CommunicationListShortData,
  archived: CommunicationListShortData,
  reports: CommunicationListShortData
});

export const CommunicationTabElement = z.object({
  id: z.string().uuid(),
  fromUserId: z.number(),
  fromUserName: z.string(),
  toUserId: z.number(),
  toUserName: z.string(),
  title: z.string().nullable(),
  content: z.string(),
  isRead: z.boolean(),
  creationDate: z.string()
});

export const CommunicationUser = z.object({
  userId: z.number(),
  userLogin: z.string()
});

export const CommunicationTabList = z.object({ communicationTabList: z.array(CommunicationTabElement) });
export const CommunicationUserList = z.object({ communicationUserList: z.array(CommunicationUser) });
export const CommunicationElementList = z.object({ id: z.string().uuid(), category: MessageDetailsCategoryEnum });
export const CommunicationElementUser = z.object({ userName: z.string(), limit: z.number() });

export type CommunicationShort = z.infer<typeof CommunicationShort>;
export type CommunicationListShortData = z.infer<typeof CommunicationListShortData>;
export type CommunicationShortList = z.infer<typeof CommunicationShortList>;
export type CommunicationTabElement = z.infer<typeof CommunicationTabElement>;
export type CommunicationTabList = z.infer<typeof CommunicationTabList>;
export type CommunicationCategoryEnum = z.infer<typeof CommunicationCategoryEnum>;
export type CommunicationElementList = z.infer<typeof CommunicationElementList>;
export type CommunicationElementUser = z.infer<typeof CommunicationElementUser>;
export type CommunicationUser = z.infer<typeof CommunicationUser>;
export type CommunicationUserList = z.infer<typeof CommunicationUserList>;
export type MessageDetailsCategoryEnum = z.infer<typeof MessageDetailsCategoryEnum>;
