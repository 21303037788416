import {
  BuildingType,
  CostResource,
  CostResourceEmpty,
  CountType,
  getData,
  PlanetResources,
  PostData,
  postData,
  PostShipSchema,
  PostShipSchemaEdit,
  Requirements,
  SchemaShipSResponse,
  ShipComponentAvailable,
  ShipComponentAvailableList,
  ShipComponentAvailableMultiOption,
  ShipComponentAvailableOption,
  ShipComponentCategories,
  ShipComponentDescription,
  ShipComponentsAvailable,
  ShipComponentsBase,
  toast,
  UserSchema
} from "@new-wars/core";
import { Command } from "@new-wars/core/src/mvvm/commands/Command/Command";
import { makeAutoObservable, runInAction } from "mobx";
import { i18n } from "next-i18next";
import { RootStore } from "../../../../store/RootStore";

export class CreateShipSchemaModel {
  private rootStore: RootStore;
  availableHulls: ShipComponentAvailableOption[] = [];
  availableDrive: ShipComponentAvailableOption[] = [];
  availableArmament: ShipComponentAvailableMultiOption[] = [];
  availableTacticalArmament: ShipComponentAvailableMultiOption[] = [];
  selectHull: ShipComponentAvailableOption | null = null;
  selectDrive: ShipComponentAvailableOption | null = null;
  selectArmament: ShipComponentAvailableMultiOption[] = [];
  selectTacticalArmament: ShipComponentAvailableMultiOption[] = [];
  shipComponentsDesc: ShipComponentDescription[] = [];
  shipComponentsBase: ShipComponentsBase[] = [];
  requirements: Requirements | null = null;
  loading = false;
  weight: number = 0;
  energy: number = 0;
  maxEnergy: number = 0;
  attack: number = 0;
  defence: number = 0;
  buildTime: number = 0;
  costToBuild: CostResource = CostResourceEmpty.parse(undefined);
  schemaName: string = "";
  drivePower: number = 0;
  createNewShipSchema: Command<() => PostData<SchemaShipSResponse>, boolean>;
  showModal: boolean = false;
  postResponse: SchemaShipSResponse = SchemaShipSResponse.OK;
  edit: boolean = false;
  editUserSchema: UserSchema | undefined = undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {});

    this.createNewShipSchema = new Command(
      async () => {
        try {
          const shipComponents = [...this.availableArmament, ...this.availableTacticalArmament].filter(
            item => item.amount > 0
          );
          const ShipComponentsList = shipComponents.map(item => ({
            shipComponentId: item.shipComponentId,
            amount: item.amount
          }));
          if (this.selectHull && this.selectDrive) {
            const hull = { shipComponentId: this.selectHull.shipComponentId, amount: 1 };
            const drive = { shipComponentId: this.selectDrive.shipComponentId, amount: 1 };
            const data: PostShipSchema = {
              name: this.schemaName,
              planetId: this.rootStore.userStore.currentPlanetId,
              ShipComponentsList: [hull, drive, ...ShipComponentsList]
            };
            const dataEdit: PostShipSchemaEdit = { ...data, Id: this.editUserSchema?.id ?? -1 };
            const result = this.edit
              ? await postData<SchemaShipSResponse>("Schema/EditSchema", dataEdit)
              : await postData<SchemaShipSResponse>("Schema/AddSchema", data);
            if (result.status && result.status === "200") {
              // @ts-ignore
              this.setResponseValue(result.data as SchemaShipSResponse);
              this.setShowModal(true);
              // @ts-ignore
              return result.data;
            }
          }
        } catch (err) {}
      },
      () => {
        return !!(
          this.selectHull &&
          this.selectDrive &&
          this.schemaName.length > 3 &&
          this.weight <= this.selectHull?.componentWeight &&
          this.energy <= this.maxEnergy
        );
      }
    );
  }

  setResponseValue(response: SchemaShipSResponse) {
    this.postResponse = response;
  }

  setShowModal = (value: boolean) => {
    this.showModal = value;
  };

  setRequirements = (requirements: Requirements | undefined) => {
    this.requirements = requirements ?? null;
  };

  getAvailableShipComponents = async (componentType: ShipComponentAvailable, planetId: number) => {
    const response = await getData(
      `ShipComponents/GetAvailableShipComponents?planetId=${planetId}&type=${componentType}`
    );
    if (response.data && response.status === "200") {
      return response.data as ShipComponentAvailableList[];
    } else {
      return undefined;
    }
  };

  setComponentDescription = (description: ShipComponentDescription[] | undefined) => {
    this.shipComponentsDesc = description ?? [];
  };

  setShipComponentsBase = (shipComponentsBase: ShipComponentsBase[] | undefined) => {
    this.shipComponentsBase = shipComponentsBase ?? [];
  };

  setLoading = (loading: boolean) => (this.loading = loading);

  getOptions = (components: ShipComponentAvailableList[], multi: boolean) => {
    return components
      .filter(x => x.isValid)
      .map((x, i) => ({ ...x, type: i + 1 }))
      .map(option => {
        const desc = this.shipComponentsDesc.find(component => component.id === option.shipComponentId);
        const component = this.shipComponentsBase.find(
          component => component.shipComponentId === option.shipComponentId
        );
        const options = {
          shipComponentId: option.shipComponentId,
          label: desc?.name ?? "",
          componentValue: component?.componentValue ?? -1,
          componentWeight: component?.componentWeight ?? -1,
          componentEnergy: component?.componentEnergy ?? -1,
          countType: component?.countType ?? -1,
          buildTime: component?.buildTime ?? -1,
          type: option.type,
          categoryId: desc?.categoryId ?? -1
        };

        if (multi) {
          return { ...options, amount: 0 };
        } else {
          return options;
        }
      });
  };

  checkIsFulfilled = (result: PromiseSettledResult<ShipComponentAvailableList[] | undefined>, multi = false) => {
    return result.status === "fulfilled" && result.value ? this.getOptions(result.value, multi) : [];
  };

  getAllComponents = async (
    description: ShipComponentDescription[] | undefined,
    shipComponents: ShipComponentsBase[] | undefined,
    requirements: Requirements | undefined
  ) => {
    const currentPlanetId = this.rootStore.userStore.currentPlanetId;
    this.setLoading(true);
    this.setComponentDescription(description);
    this.setShipComponentsBase(shipComponents);
    this.setRequirements(requirements);
    const components = await Promise.allSettled([
      this.getAvailableShipComponents(ShipComponentsAvailable.Hull, currentPlanetId),
      this.getAvailableShipComponents(ShipComponentsAvailable.Drive, currentPlanetId),
      this.getAvailableShipComponents(ShipComponentsAvailable.Armament, currentPlanetId),
      this.getAvailableShipComponents(ShipComponentsAvailable.TacticalArmament, currentPlanetId)
    ]);

    runInAction(() => {
      const [hulls, drives, armaments, tacticalArmaments] = components;

      if (this.shipComponentsBase.length > 0) {
        this.availableHulls = this.checkIsFulfilled(hulls);
        this.availableDrive = this.checkIsFulfilled(drives);
        this.availableArmament = ShipComponentAvailableMultiOption.array().parse(
          this.checkIsFulfilled(armaments, true)
        );
        this.availableTacticalArmament = ShipComponentAvailableMultiOption.array().parse(
          this.checkIsFulfilled(tacticalArmaments, true)
        );
      }

      if (this.edit) {
        if (this.editUserSchema && UserSchema.safeParse(this.editUserSchema).success) {
          this.editUserSchema.schemaItems.forEach(item => {
            if (!this.selectHull && this.availableHulls.length > 0) {
              const hull = this.availableHulls.find(hull => hull.shipComponentId === item.componentId);

              if (hull) {
                return (this.selectHull = hull);
              }
            } else if (!this.selectDrive && this.availableDrive.length > 0) {
              const drive = this.availableDrive.find(drive => drive.shipComponentId === item.componentId);

              if (drive) {
                return (this.selectDrive = drive);
              }
            } else if (this.availableTacticalArmament.length > 0 && this.availableArmament.length > 0) {
              this.setSelectArmament(item.componentId, item.amount);
              this.setSelectTacticalArmament(item.componentId, item.amount);
            } else {
              console.error("Error EDIT SETTING", { item });
            }
          });
        } else {
          console.error("Error EDIT ", { editUserSchema: this.editUserSchema });
          // Show error
        }
      }

      this.setLoading(false);
    });
  };

  setSelectHull = (component: ShipComponentAvailableOption) => {
    this.selectHull = component;
    this.countAll();
  };

  setSelectDrive = (component: ShipComponentAvailableOption) => {
    this.selectDrive = component;
    this.countAll();
  };

  setSelectTacticalArmament = (id: number, amount: number) => {
    this.availableTacticalArmament = this.availableTacticalArmament.map(item =>
      item.shipComponentId === id ? { ...item, amount } : item
    );
    this.countAll();
  };

  setSelectArmament = (id: number, amount: number) => {
    this.availableArmament = this.availableArmament.map(item =>
      item.shipComponentId === id ? { ...item, amount } : item
    );

    this.countAll();
  };

  countAll = (components?: ShipComponentAvailableOption[] | ShipComponentAvailableMultiOption[]) => {
    const weightHull = components
      ? components.find(x => x.categoryId === ShipComponentCategories.Hull)?.componentWeight ?? 0
      : this.selectHull?.componentWeight ?? 0;
    let weightArmament = 0;
    let energyArmament = 0;
    let attack = 0;
    let defence = 0;
    let time = 0;
    let cost = CostResourceEmpty.parse(undefined);
    let drivePower = 0;

    const calc = ({
      item,
      isDrive = false,
      isHull = false
    }: {
      item: ShipComponentAvailableOption | ShipComponentAvailableMultiOption;
      isDrive?: boolean;
      isHull?: boolean;
    }) => {
      const sp = ShipComponentAvailableMultiOption.safeParse(item);
      const isPercentage = item.countType === CountType.percentage;
      const amount = sp.success ? sp.data.amount ?? 1 : 1;
      const { shipComponentId } = item;
      let weight = 0;
      let energy = 0;
      if (isDrive) {
        const percentageWeight = (item.componentWeight / 100) * weightHull;
        weight = isPercentage ? percentageWeight : item.componentWeight * amount;
        this.maxEnergy = -(isPercentage ? percentageWeight : item.componentEnergy * amount);
      } else if (!isHull) {
        weight = isPercentage ? (item.componentWeight / 100) * amount : item.componentWeight * amount;
        energy = isPercentage ? (item.componentEnergy / 100) * amount : item.componentEnergy * amount;
      }

      const componentValue = isPercentage ? (item.componentValue / 100) * amount : item.componentValue * amount;
      const timeToBuild = item.buildTime * amount;
      const planetResources = PlanetResources.parse(undefined);
      if (this.requirements && amount > 0) {
        const itemCost = this.rootStore.buildingStore.getBuildingCost(
          this.requirements,
          shipComponentId,
          0,
          planetResources,
          BuildingType.Enum.ShipComponent,
          this.rootStore.userStore.raceId,
          1
        );
        cost.crystal += itemCost.crystalCost * amount;
        cost.metal += itemCost.metalCost * amount;
        cost.frubin += itemCost.frubinCost * amount;
        cost.frurozin += itemCost.frurozinCost * amount;
        cost.gold += itemCost.goldCost * amount;
        cost.orizin += itemCost.orizinCost * amount;
      }

      weightArmament = weightArmament + weight;
      energyArmament = energyArmament + energy;
      switch (item.categoryId) {
        case ShipComponentCategories.Hull:
          break;
        case ShipComponentCategories.Drive:
          drivePower = componentValue + drivePower;
          break;
        case ShipComponentCategories.Armor:
          attack = componentValue + attack;
          break;
        case ShipComponentCategories.Armament:
          defence = componentValue + defence;
          break;
        case ShipComponentCategories.TacticalArmament:
          defence = componentValue + defence;
          break;
        case ShipComponentCategories.Cargo:
          defence = componentValue + defence;
          break;
        case ShipComponentCategories.Special:
          defence = componentValue + defence;
          break;
        default:
          break;
      }
      time = time + timeToBuild;
    };
    if (components && components?.length > 0) {
      components.map(component =>
        calc({
          item: component,
          isHull: component.categoryId === ShipComponentCategories.Hull,
          isDrive: component.categoryId === ShipComponentCategories.Drive
        })
      );
    } else {
      this.selectHull && calc({ item: this.selectHull, isHull: true });
      this.selectDrive && calc({ item: this.selectDrive, isDrive: true });

      this.availableArmament.forEach(item => {
        item.amount > 0 && calc({ item });
      });

      this.availableTacticalArmament.forEach(item => {
        item.amount > 0 && calc({ item });
      });
    }
    if (this.selectHull) {
      this.weight = -weightArmament;
      this.energy = -energyArmament;
      this.buildTime = time;
      this.costToBuild = cost;
      this.attack = attack;
      this.defence = defence;
      this.drivePower = drivePower;
    }

    return { weight: -weightArmament, energy: -energyArmament, attack, defence, drivePower, cost, buildTime: time };
  };

  deleteSchema = async (schemaId: number, schemaName: string) => {
    try {
      this.setLoading(true);
      const response = await postData(`Schema/DeleteSchema/`, { schemaId });
      this.setLoading(false);
      toast({
        title: response
          ? `${i18n?.t("startedResearch", { ns: "common" })}: ${schemaName}`
          : i18n?.t("somethingWentWrong", { ns: "common" }),
        status: response ? "success" : "error",
        isClosable: true
      });
      console.log("startDefenceSystemRecycle", response); //@ts-ignore
      return response.data > 0;
    } catch (error) {
      this.setLoading(false);
      console.error("startDefenceSystemRecycle", error);
    }
  };

  setEditSchema = (schema: UserSchema) => {
    this.edit = true;
    this.schemaName = schema.schemaName;
    this.buildTime = schema.totalBuildTime;
    this.editUserSchema = schema;
    this.loading = true; // TODO: check if really need here
  };

  resetSchema = () => {
    this.edit = false;
    this.editUserSchema = undefined;
    this.loading = false;
    this.weight = 0;
    this.energy = 0;
    this.maxEnergy = 0;
    this.attack = 0;
    this.defence = 0;
    this.buildTime = 0;
    this.costToBuild = CostResourceEmpty.parse(undefined);
    this.schemaName = "";
    this.drivePower = 0;
    this.showModal = false;
    this.postResponse = SchemaShipSResponse.OK;
  };
}
