import { z } from "zod";

export const Planet = z.object({
  galaxyId: z.number(),
  systemId: z.number(),
  planetId: z.number(),
  planetType: z.number(),
  planetName: z.string(),
  ownerId: z.number()
});

export const PlanetType = z.object({
  id: z.number(),
  name: z.string(),
  moonCount: z.number(),
  percentageIron: z.number(),
  percentageKristall: z.number(),
  percentageFrubin: z.number(),
  percentageOrizin: z.number(),
  percentageFrurozin: z.number(),
  percentageGold: z.number(),
  canProduceGold: z.boolean()
});

export const UserPlanet = z.object({
  id: z.number(),
  userId: z.number(),
  planetId: z.number(),
  planetName: z.string(),
  planetTypeId: z.number(),
  spaceAmount: z.number(),
  spaceMaxAmount: z.number(),
  energyAmount: z.number(),
  energyMaxAmount: z.number(),
  energyLevel: z.number()
});

export const PlanetEnergyStats = z.object({
  energyAmount: z.number(),
  energyMaxAmount: z.number(),
  energyLevel: z.number()
});

export type Planet = z.infer<typeof Planet>;
export type PlanetType = z.infer<typeof PlanetType>;
export type UserPlanet = z.infer<typeof UserPlanet>;
export type PlanetEnergyStats = z.infer<typeof Planet>;
