import { z } from "zod";

export const User = z.object({
  id: z.number(),
  raceId: z.number(),
  login: z.string(),
  email: z.string(),
  passwordAttempts: z.number(),
  token: z.string(),
  isActive: z.boolean(),
  isBlocked: z.boolean(),
  gameStart: z.boolean()
});

export const UserList = User.pick({
  raceId: true,
  login: true
}).extend({
  fractionId: z.number(),
  totalPoints: z.number()
});
export const UserListApi = z.object({
  userListData: z.array(UserList)
});

export const UserBonus = z.object({
  bonusId: z.number(),
  bonusPercentageValue: z.number(),
  bonusModificator: z.number(),
  bonusValue: z.number()
});

export type User = z.infer<typeof User>;
export type UserBonus = z.infer<typeof UserBonus>;
export type UserList = z.infer<typeof UserList>;
export type UserListApi = z.infer<typeof UserListApi>;
