import { z } from "zod";

export type Option = {
  value: string;
  label: string;
  [key: string]: unknown;
};

export enum ProgressType {
  Upgrade,
  Building,
  Technology
}

export const ProgressTypes = z.nativeEnum(ProgressType);

export const ProgressBasic = z.object({
  id: z.number(),
  planetId: z.number(),
  buildStart: z.date(),
  buildEnd: z.date()
});
export type ProgressBasic = z.infer<typeof ProgressBasic>;

export const Progress = ProgressBasic.extend({
  attackValue: z.number(),
  defenseValue: z.number(),
  progressType: ProgressTypes
});

export type Progress = z.infer<typeof Progress>;

export const ActiveProgress = ProgressBasic.extend({
  currentLevel: z.number(),
  nextLevel: z.number()
});

export type ActiveProgress = z.infer<typeof ActiveProgress>;

export const STATS_FROM = z.enum(["all", "ships", "defence", "building", "hidden"]);

export type STATS_FROM = z.infer<typeof STATS_FROM>;
