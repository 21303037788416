import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";
import { ShipStat } from "../types";

export function useShipUserStats(planetId: number) {
  const {
    data: shipUserStat,
    mutate: mutateShipUserStat,
    error: errorShipUserStat,
    isValidating: isValidatingShipUserStat
  } = useSWRImmutable<ShipStat>(planetId ? `/Ships/GetUserShipsStats/${planetId}` : null);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [shipUserStat]);

  return { shipUserStat, mutateShipUserStat, errorShipUserStat, isValidatingShipUserStat };
}

export default useShipUserStats;
