import { z } from "zod";
import { PlanetEnergyStats } from "./planet";

export const HullTypeShip = { 1: "light", 2: "medium", 3: "heavy", 4: "sond", 5: "tactical" } as const;

export type HullTypeShip = keyof typeof HullTypeShip;

export const DriveTypeShip = { 6: "buster", 7: "nuclear", 8: "ionic", 9: "hiper", 10: "gravity" } as const;

export type DriveTypeShip = keyof typeof DriveTypeShip;

export const SchemaAdditionalItems = z.object({
  hullType: z.nativeEnum(HullTypeShip),
  engineType: z.nativeEnum(DriveTypeShip),
  engineAdditionalPower: z.number(),
  isPlanetScaner: z.boolean(),
  isSystemScaner: z.boolean(),
  isObservation: z.boolean(),
  isColonization: z.boolean(),
  isStealth: z.boolean(),
  isCommunication: z.boolean()
});

export type SchemaAdditionalItems = z.infer<typeof SchemaAdditionalItems>;

export const UserShip = z.object({
  userId: z.number(),
  planetId: z.number(),
  shipId: z.number(),
  schemaId: z.number(),
  schemaName: z.string(),
  attack: z.number(),
  defense: z.number(),
  cargo: z.number(),
  enginePower: z.number(),
  amount: z.number(),
  additionalData: SchemaAdditionalItems
});

export type UserShip = z.infer<typeof UserShip>;

export const UserShipBuild = UserShip.extend({
  metalCost: z.number(),
  crystalCost: z.number(),
  frubinCost: z.number(),
  orizinCost: z.number(),
  frurozinCost: z.number(),
  goldCost: z.number(),
  buildTime: z.number()
});

export type UserShipBuild = z.infer<typeof UserShipBuild>;

export const ShipsStatsMain = UserShip.extend({
  attackSum: z.number(),
  defenseSum: z.number()
});

export type ShipsStatsMain = z.infer<typeof ShipsStatsMain>;

export const ShipStat = z.object({
  planetId: z.number(),
  planetEnergyStats: PlanetEnergyStats,
  shipsStatsMain: ShipsStatsMain
});

export type ShipStat = z.infer<typeof ShipStat>;
