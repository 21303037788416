import { useEffect } from "react";
import useSWR from "swr";
import { FactionForRace } from "../types";

export function useFactionForRace({ redirectTo = "", redirectIfFound = false } = {}) {
  const {
    data: factionForRace,
    mutate: mutateFactionForRace,
    error: errorFactionForRace,
    isValidating: isValidatingFactionForRace
  } = useSWR<FactionForRace[]>("/Faction/GetFactionsForRace");

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [factionForRace, redirectIfFound, redirectTo]);

  return { factionForRace, mutateFactionForRace, errorFactionForRace, isValidatingFactionForRace };
}

export default useFactionForRace;
