import { useEffect } from "react";
import useSWR from "swr";
import { GameSummary } from "../types";

export function useGameSummaryPerPlanet(planetId: number) {
  const {
    data: gameSummary,
    mutate: mutateGameSummary,
    error: errorGameSummary,
    isValidating: isValidatingGameSummary
  } = useSWR<GameSummary>(`/Game/Summary/${planetId}`, { refreshInterval: 10000 });

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [gameSummary]);

  return { gameSummary, mutateGameSummary, errorGameSummary, isValidatingGameSummary };
}

export default useGameSummaryPerPlanet;
