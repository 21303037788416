import { useEffect } from "react";
import useSWR from "swr";
import { ProductionList } from "../types";
export function usePlanetProduction(planetId: number) {
  const {
    data: planetProduction,
    mutate: mutatePlanetProduction,
    error: errorPlanetProduction,
    isValidating: isValidatingPlanetProduction
  } = useSWR<ProductionList>(planetId ? `/Production/GetPlanetProduction/${planetId}` : null);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [planetProduction]);

  return { planetProduction, mutatePlanetProduction, errorPlanetProduction, isValidatingPlanetProduction };
}

export default usePlanetProduction;
