import {
  Fleet,
  FleetAddBody,
  FleetAvailableShips,
  FleetEditBody,
  FleetItems,
  PostData,
  postData,
  SchemaShipSResponse
} from "@new-wars/core";
import { Command } from "@new-wars/core/src/mvvm/commands/Command/Command";
import { makeAutoObservable, runInAction } from "mobx";
import { KeyedMutator } from "swr";
import { RootStoreModel } from "./RootStore";

export class FleetStore {
  private rootStore: RootStoreModel;
  fleetName: string = "";
  loading: boolean = false;
  fleetItems: Map<number, FleetItems> = new Map();
  addNewFleet: Command<() => PostData<SchemaShipSResponse>, boolean>;
  editFleet: Command<() => PostData<SchemaShipSResponse>, boolean>;
  fleetId: number | null = null;
  isFleetDetailsOpen: boolean = false;
  isFleetEditOpen: boolean = false;

  constructor(rootStore: RootStoreModel) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
    this.addNewFleet = new Command(
      async () => {
        try {
          this.setLoading(true);
          const fleetItems = Array.from(this.fleetItems.values());
          const body: FleetAddBody = {
            planetId: this.rootStore.userStore.currentPlanetId,
            fleetName: this.fleetName,
            fleetItems
          };
          const response = await postData("Fleet/AddFleet", body);
          runInAction(() => {
            this.resetFleet();
          });
          this.setLoading(false);
          console.log("addNewFleet", response);
          //@ts-ignore
          return response.data;
        } catch (error) {
          console.error("addNewFleet", error);
          this.setLoading(false);
        }
      },
      () => {
        return !!((this.fleetName.length > 3 && this.fleetItems.size > 0) || !this.loading);
      }
    );

    this.editFleet = new Command(
      async () => {
        try {
          const fleetItems = Array.from(this.fleetItems.values());
          const body: FleetEditBody = {
            fleetId: this.fleetId ?? -1,
            planetId: this.rootStore.userStore.currentPlanetId,
            fleetName: this.fleetName,
            fleetItems
          };
          this.setLoading(true);
          const response = await postData("Fleet/EditFleet", body);
          runInAction(() => {
            this.resetFleet();
          });
          console.log("editFleet", response);
          this.setLoading(false);
          //@ts-ignore
          return response.data;
        } catch (error) {
          console.error("editFleet", error);
          this.setLoading(false);
        }
      },
      () => {
        return !!((this.fleetName.length > 3 && this.fleetItems.size > 0) || !this.loading);
      }
    );
  }

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  deleteFleet = async (
    fleetId: number,
    planetId: number,
    mutate: KeyedMutator<Fleet[]>,
    mutateShips: KeyedMutator<FleetAvailableShips[]>
  ) => {
    try {
      this.setLoading(true);
      const response = await postData("Fleet/DeleteFleet", {
        fleetId,
        planetId
      });
      mutate();
      mutateShips();
      this.setLoading(false);
      console.log("deleteFleet", response); //@ts-ignore
      return response.data > 0;
    } catch (error) {
      this.setLoading(false);
      console.error("deleteFleet", error);
    }
  };

  getFleetItem = (shipId: number) => {
    return this.fleetItems.get(shipId);
  };

  setFleetItem = (item: FleetItems) => {
    this.fleetItems.set(item.shipId, item);
  };

  openFleetDetails = (fleetId: number) => {
    this.fleetId = fleetId;
    this.isFleetDetailsOpen = true;
  };

  closeFleetDetails = () => {
    this.fleetId = null;
    this.isFleetDetailsOpen = false;
  };

  openEditFleet = (fleetId: number, name: string) => {
    this.fleetId = fleetId;
    this.isFleetEditOpen = true;
    this.fleetName = name;
  };

  closeFleetEdit = () => {
    this.fleetId = null;
    this.isFleetEditOpen = false;
    this.fleetName = "";
  };

  setFleetForEdit = (fleet: FleetAvailableShips[]) => {
    fleet.map(ship => {
      const item: FleetItems = { amount: ship.currentAmount, shipId: ship.shipId };
      this.setFleetItem(item);
    });
  };

  resetFleet = () => {
    this.fleetName = "";
    this.fleetItems = new Map();
    this.isFleetDetailsOpen = false;
    this.isFleetEditOpen = false;
    this.fleetId;
  };
}
