import { z } from "zod";
import { ActiveProgress } from "./common";
import { Technology } from "./technology";

export const Building = z.object({
  id: z.number(),
  buildingId: z.number(),
  planetId: z.number(),
  buildingLevel: z.number(),
  buildTime: z.number()
});

export const BuildingProgress = ActiveProgress.extend({
  buildingId: z.number(),
  planetBuildingId: z.number()
});

export type Building = z.infer<typeof Building>;
export type BuildingProgress = z.infer<typeof BuildingProgress>;

export function isBuilding(building: Building | Technology): building is Building {
  return (building as Building).buildingLevel !== undefined;
}

export const BuildingsStats = z.object({
  spaceAmount: z.number(),
  spaceMaxAmount: z.number(),
  energyAmount: z.number(),
  energyMaxAmount: z.number(),
  energyLevel: z.number()
});

export type BuildingsStats = z.infer<typeof BuildingsStats>;
