import { z } from "zod";

export const Cost = z.object({
  crystalCost: z.number(),
  metalCost: z.number(),
  frubinCost: z.number(),
  orizinCost: z.number(),
  frurozinCost: z.number(),
  goldCost: z.number()
});

export const BuildingsCost = Cost.extend({ buildingId: z.number(), buildingLevel: z.number() });

export const TechnologiesCost = Cost.extend({ technologyId: z.number(), technologyLevel: z.number() });

export const RequirementResourcesPerRace = Cost.extend({
  crystalHighCost: z.number(),
  crystalLowCost: z.number(),
  frubinHighCost: z.number(),
  frubinLowCost: z.number(),
  frurozinHighCost: z.number(),
  frurozinLowCost: z.number(),
  goldHighCost: z.number(),
  goldLowCost: z.number(),
  metalHighCost: z.number(),
  metalLowCost: z.number(),
  orizinHighCost: z.number(),
  orizinLowCost: z.number()
});

export const DefenseSystemsCost = RequirementResourcesPerRace.extend({
  defenseSystemId: z.number()
});

export const ShipComponentsCost = RequirementResourcesPerRace.extend({
  shipComponentId: z.number()
});

export const DefenseOrShipComponentsCost = z.union([DefenseSystemsCost, ShipComponentsCost]);

export const Requirement = z.object({
  requirementId: z.number(),
  requirementLevel: z.number(),
  requirementType: z.number()
});

export const BuildingsRequirements = Requirement.extend({
  buildingId: z.number()
});

export const TechnologiesRequirements = Requirement.extend({
  technologyId: z.number()
});

export const DefenseSystemsRequirements = Requirement.extend({
  defenseSystemId: z.number()
});

export const ShipComponentsRequirements = Requirement.extend({
  shipComponentId: z.number()
});

export const Requirements = z.object({
  buildingsRequirements: z.array(BuildingsRequirements),
  buildingsCost: z.array(BuildingsCost),
  defenseSystemsCost: z.array(DefenseSystemsCost),
  defenseSystemsRequirements: z.array(DefenseSystemsRequirements),
  technologiesRequirements: z.array(TechnologiesRequirements),
  technologiesCost: z.array(TechnologiesCost),
  shipComponentsRequirements: z.array(ShipComponentsRequirements),
  shipComponentsCost: z.array(ShipComponentsCost)
});

export enum RequirementTypes {
  Building,
  Technology
}

export const RequirementType = z.nativeEnum(RequirementTypes);

export type RequirementType = z.infer<typeof RequirementType>;

export type RequirementResourcesPerRace = z.infer<typeof RequirementResourcesPerRace>;

export type Cost = z.infer<typeof Cost>;

export type BuildingsCost = z.infer<typeof BuildingsCost>;

export type TechnologiesCost = z.infer<typeof TechnologiesCost>;

export type DefenseSystemsCost = z.infer<typeof DefenseSystemsCost>;

export type Requirement = z.infer<typeof Requirement>;

export type BuildingsRequirements = z.infer<typeof BuildingsRequirements>;

export type TechnologiesRequirements = z.infer<typeof TechnologiesRequirements>;

export type DefenseSystemsRequirements = z.infer<typeof DefenseSystemsRequirements>;

export type Requirements = z.infer<typeof Requirements>;

export const Resources = z.enum(["metal", "crystal", "frubin", "frurozin", "gold", "orizin"]);

export type Resources = z.infer<typeof Resources>;

export type ShipComponentsCost = z.infer<typeof ShipComponentsCost>;

export type ShipComponentsRequirements = z.infer<typeof ShipComponentsRequirements>;

export type DefenseOrShipComponentsCost = z.infer<typeof DefenseOrShipComponentsCost>;
