import { z } from "zod";

export const RaceBonus = z.object({
  raceId: z.number(),
  bonusId: z.number(),
  bonusValue: z.number()
});

export enum Race {
  Earthlings = 1,
  Xianianer,
  Noberians,
  Bugserians
}

export const RaceType = z.nativeEnum(Race);

export type RaceType = z.infer<typeof RaceType>;

export type RaceBonus = z.infer<typeof RaceBonus>;
