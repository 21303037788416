import { DashboardPlanet, DashboardPlanets, Race, User, postData } from "@new-wars/core";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { KeyedMutator } from "swr";
import z from "zod";
import { RootStoreModel } from "./RootStore";

const IUserStore = User.partial();

type IUserStore = z.infer<typeof IUserStore>;

export class UserStore implements IUserStore {
  private rootStore: RootStoreModel;
  userId?: number = undefined;
  currentPlanetId: number = 0;
  loading: boolean = false;
  raceId: Race = 1;

  constructor(rootStore: RootStoreModel) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;

    makePersistable(this, {
      name: "UserStore",
      properties: ["userId", "currentPlanetId", "raceId"]
    });
  }

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  selectPlanetId = (planetId: number) => {
    this.currentPlanetId = planetId;
  };

  setRace = (raceId: Race) => {
    this.raceId = raceId;
  };

  setUserId = (userId: number) => {
    this.userId = userId;
  };

  onEditPlanetName = async (
    planetName: string,
    planetId: number,
    mutate: KeyedMutator<DashboardPlanet>,
    mutateGameDashboardPlanets: KeyedMutator<DashboardPlanets>
  ) => {
    try {
      this.setLoading(true);
      const response = await postData("Planet/ChangePlanetName", { planetId, planetName });
      mutate();
      mutateGameDashboardPlanets();
      this.setLoading(false);
      return response?.status === "200";
    } catch (error) {
      this.setLoading(false);
      return false;
    }
  };
}
