import { z } from "zod";

export const Resource = z.object({
  name: z.string(),
  amount: z.number(),
  maxAmount: z.number(),
  production: z.number(),
  productionBonus: z.number()
});

export const Metal = z
  .object({
    metalAmount: z.number(),
    metalMaxAmount: z.number(),
    metalProduction: z.number(),
    metalProductionBonus: z.number()
  })
  .default({ metalAmount: 0, metalMaxAmount: 0, metalProduction: 0, metalProductionBonus: 0 });

export const Crystal = z
  .object({
    crystalAmount: z.number(),
    crystalMaxAmount: z.number(),
    crystalProduction: z.number(),
    crystalProductionBonus: z.number()
  })
  .default({ crystalAmount: 0, crystalMaxAmount: 0, crystalProduction: 0, crystalProductionBonus: 0 });

export const Frubin = z
  .object({
    frubinAmount: z.number(),
    frubinMaxAmount: z.number(),
    frubinProduction: z.number(),
    frubinProductionBonus: z.number()
  })
  .default({ frubinAmount: 0, frubinMaxAmount: 0, frubinProduction: 0, frubinProductionBonus: 0 });

export const Orizin = z
  .object({
    orizinAmount: z.number(),
    orizinMaxAmount: z.number(),
    orizinProduction: z.number(),
    orizinProductionBonus: z.number()
  })
  .default({ orizinAmount: 0, orizinMaxAmount: 0, orizinProduction: 0, orizinProductionBonus: 0 });

export const Frurozin = z
  .object({
    frurozinAmount: z.number(),
    frurozinMaxAmount: z.number(),
    frurozinProduction: z.number(),
    frurozinProductionBonus: z.number()
  })
  .default({ frurozinAmount: 0, frurozinMaxAmount: 0, frurozinProduction: 0, frurozinProductionBonus: 0 });

export const Gold = z
  .object({
    goldAmount: z.number(),
    goldMaxAmount: z.number(),
    goldProduction: z.number(),
    goldProductionBonus: z.number()
  })
  .default({ goldAmount: 0, goldMaxAmount: 0, goldProduction: 0, goldProductionBonus: 0 });

export type Resource = z.infer<typeof Resource>;

export type Metal = z.infer<typeof Metal>;

export type Crystal = z.infer<typeof Crystal>;

export type Orizin = z.infer<typeof Orizin>;

export type Frubin = z.infer<typeof Frubin>;

export type Frurozin = z.infer<typeof Frurozin>;

export type Gold = z.infer<typeof Gold>;

export const PlanetResources = z
  .object({
    PlanetId: z.number(),
    metal: Metal,
    crystal: Crystal,
    orizin: Orizin,
    frubin: Frubin,
    frurozin: Frurozin,
    gold: Gold
  })
  .default({
    PlanetId: 0,
    metal: Metal.parse(undefined),
    crystal: Crystal.parse(undefined),
    orizin: Orizin.parse(undefined),
    frubin: Frubin.parse(undefined),
    frurozin: Frurozin.parse(undefined),
    gold: Gold.parse(undefined)
  });

export type PlanetResources = z.infer<typeof PlanetResources>;

export const CostResource = z.object({
  metal: z.number(),
  crystal: z.number(),
  frubin: z.number(),
  orizin: z.number(),
  frurozin: z.number(),
  gold: z.number()
});

export const CostResourceEmpty = CostResource.default({
  metal: 0,
  crystal: 0,
  frubin: 0,
  frurozin: 0,
  orizin: 0,
  gold: 0
});

export type CostResource = z.infer<typeof CostResource>;

export const ResourceRowCost = z.object({ cost: z.number().optional(), isEnough: z.boolean().optional() });
export type ResourceRowCost = z.infer<typeof ResourceRowCost>;

export const ResourceCost = z.object({
  metal: ResourceRowCost,
  crystal: ResourceRowCost,
  frubin: ResourceRowCost,
  orizin: ResourceRowCost,
  frurozin: ResourceRowCost,
  gold: ResourceRowCost
});

export type ResourceCost = z.infer<typeof ResourceCost>;
