import { useEffect } from "react";
import useSWR from "swr";
import { RaceBonus } from "../types";

export function useRaceBonuses({ redirectTo = "", redirectIfFound = false } = {}) {
  const {
    data: raceBonuses,
    mutate: mutateRaceBonuses,
    error: errorRaceBonuses,
    isValidating: isValidatingRaceBonuses
  } = useSWR<RaceBonus[]>("/Race/GetRaceBonuses");

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [raceBonuses, redirectIfFound, redirectTo]);

  return { raceBonuses, mutateRaceBonuses, errorRaceBonuses, isValidatingRaceBonuses };
}

export default useRaceBonuses;
