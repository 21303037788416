import { createStandaloneToast } from "@chakra-ui/react";
import { NavItem } from "@new-wars/common";
import dayjs from "dayjs";
import { FiLogIn } from "react-icons/fi";
import { MdFastfood } from "react-icons/md";
import { z } from "zod";
import {
  Building,
  BuildingsCost,
  BuildingsRequirements,
  DefenseSystemsCost,
  DefenseSystemsRequirements,
  Description,
  RequirementResourcesPerRace,
  ShipComponentsCost,
  TechnologiesCost,
  TechnologiesRequirements,
  Technology
} from "../types";

export const { ToastContainer, toast } = createStandaloneToast();

export function spliceIntoChunks(arr: Array<any>, chunkSize: number) {
  const data = [...arr];
  const res = [];
  while (data.length > 0) {
    const chunk = data.splice(0, chunkSize);
    res.push(chunk);
  }
  return res;
}

export const isValueAnEmptyObject = (value: any) => typeof value === "object" && Object.keys(value).length === 0;
export const isNullOrUndefined = (value: any) => value === undefined || value === null;

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const EMPTY_TECHNOLOGY_COST: TechnologiesCost = Object.freeze({
  technologyId: -1,
  technologyLevel: -1,
  metalCost: -1,
  crystalCost: -1,
  frubinCost: -1,
  orizinCost: -1,
  frurozinCost: -1,
  goldCost: -1
});

export const EMPTY_REQ_COST: RequirementResourcesPerRace = Object.freeze({
  metalCost: -1,
  crystalCost: -1,
  frubinCost: -1,
  orizinCost: -1,
  frurozinCost: -1,
  goldCost: -1,
  crystalHighCost: -1,
  crystalLowCost: -1,
  frubinHighCost: -1,
  frubinLowCost: -1,
  frurozinHighCost: -1,
  frurozinLowCost: -1,
  goldHighCost: -1,
  goldLowCost: -1,
  metalHighCost: -1,
  metalLowCost: -1,
  orizinHighCost: -1,
  orizinLowCost: -1
});

export const EMPTY_DEFENSE_SYSTEM_COST: DefenseSystemsCost = Object.freeze({
  defenseSystemId: -1,
  ...EMPTY_REQ_COST
});

export const EMPTY_SHIP_COMPONENTS_COST: ShipComponentsCost = Object.freeze({
  shipComponentId: -1,
  ...EMPTY_REQ_COST
});

export const EMPTY_BUILDING_COST: BuildingsCost = Object.freeze({
  buildingId: -1,
  buildingLevel: -1,
  metalCost: -1,
  crystalCost: -1,
  frubinCost: -1,
  orizinCost: -1,
  frurozinCost: -1,
  goldCost: -1
});

export const EMPTY_BUILDINGS_REQUIREMENTS: BuildingsRequirements = Object.freeze({
  buildingId: -1,
  requirementId: -1,
  requirementLevel: -1,
  requirementType: -1
});

export const EMPTY_TECHNOLOGIES_REQUIREMENTS: TechnologiesRequirements = Object.freeze({
  technologyId: -1,
  requirementId: -1,
  requirementLevel: -1,
  requirementType: -1
});

export const EMPTY_DEFENSE_SYSTEMS_REQUIREMENTS: DefenseSystemsRequirements = Object.freeze({
  defenseSystemId: -1,
  requirementId: -1,
  requirementLevel: -1,
  requirementType: -1
});

export const EMPTY_BUILDING: Building = Object.freeze({
  id: -1,
  buildingId: -1,
  planetId: -1,
  buildingLevel: -1,
  buildTime: -1
});

export const EMPTY_TECHNOLOGY: Technology = Object.freeze({
  id: -1,
  technologyId: -1,
  technologyValue: -1,
  discoverTime: -1
});
export const EMPTY_DESCRIPTION: Description = Object.freeze({ id: 0, name: "", description: "" });
export const BuildingType = z.enum([
  "Technology",
  "Building",
  "DefenceSystem",
  "DefenceSystemUpgrade",
  "ShipComponent"
]);
export type BuildingType = z.infer<typeof BuildingType>;

export const COMPANY_NAME = "New wars";
export const COMPANY_BUTTON = "Zarejestruj";
export const HEADER_NAV_ITEMS: NavItem[] = [
  { title: "dashboard", route: "/dashboard", icon: FiLogIn, showInTopBar: true, authentication: true },
  { title: "ranking", route: "/ranking/summary", icon: FiLogIn, showInTopBar: true, authentication: true },
  { title: "users", route: "/users", icon: FiLogIn, showInTopBar: true, authentication: true },
  { title: "buildings", route: "/buildings/summary", icon: FiLogIn, showInTopBar: true, authentication: true },
  { title: "technology", route: "/technology/summary", icon: FiLogIn, showInTopBar: true, authentication: true },
  { title: "planets", route: "/planet/summary", icon: FiLogIn, showInTopBar: true, authentication: true },
  { title: "galaxy", route: "/galaxy/summary", icon: FiLogIn, showInTopBar: true, authentication: true },
  { title: "defense", route: "/defense/summary", icon: FiLogIn, showInTopBar: true, authentication: true },
  { title: "bonus", route: "/bonus/summary", icon: FiLogIn, showInTopBar: true, authentication: true },
  { title: "schemas", route: "/schema/summary", icon: FiLogIn, showInTopBar: true, authentication: true },
  { title: "ships", route: "/ship/summary", icon: FiLogIn, showInTopBar: true, authentication: true },
  { title: "fleet", route: "/fleet/summary", icon: FiLogIn, showInTopBar: true, authentication: true },
  { title: "production", route: "/production/summary", icon: MdFastfood, showInTopBar: true, authentication: true },
  { title: "login", route: "/login", icon: FiLogIn, showInTopBar: false, authentication: false },
  { title: "register", route: "/register", icon: MdFastfood, showInTopBar: false, authentication: false },
  { title: "logout", route: "/logout", icon: FiLogIn, showInTopBar: false, authentication: true }
];

export const formatUnit = (unit: number | undefined) => (unit ? `${unit}:` : "");

export const defaultEnough = {
  isMetalEnough: false,
  isCrystalEnough: false,
  isOrizinEnough: false,
  isFrubinEnough: false,
  isFrurozinEnough: false,
  isGoldEnough: false
};

export const defaultReturn = (typeBuildings: BuildingType) => {
  switch (typeBuildings) {
    case BuildingType.Enum.Building:
      return { isEnoughResources: false, ...EMPTY_BUILDING_COST, ...defaultEnough };
    case BuildingType.Enum.Technology:
      return { isEnoughResources: false, ...EMPTY_TECHNOLOGY_COST, ...defaultEnough };
    case BuildingType.Enum.DefenceSystem:
      return { isEnoughResources: false, ...EMPTY_DEFENSE_SYSTEM_COST, ...defaultEnough };
    default:
      return { isEnoughResources: false, ...EMPTY_SHIP_COMPONENTS_COST, ...defaultEnough };
  }
};

export const formatWithPercentage = (value: number, showAsPercent: boolean) => `${value}${showAsPercent ? "%" : ""}`;

export const formatSecondsToHuman = (seconds: number) => dayjs.duration(seconds, "seconds").format("D:HH:mm:ss");
