import {
  Building,
  BuildingType,
  defaultReturn,
  DefenseOrShipComponentsCost,
  EMPTY_BUILDING,
  EMPTY_BUILDINGS_REQUIREMENTS,
  EMPTY_DEFENSE_SYSTEMS_REQUIREMENTS,
  EMPTY_TECHNOLOGIES_REQUIREMENTS,
  EMPTY_TECHNOLOGY,
  GameSummaryBuildings,
  GameSummaryProgress,
  isBuilding,
  PlanetResources,
  postData,
  RaceType,
  Requirements,
  RequirementType,
  Resources,
  Technology
} from "@new-wars/core";
import { makeAutoObservable } from "mobx";
import { KeyedMutator } from "swr";
import { RootStoreModel } from "./RootStore";

export class BuildingStore {
  private rootStore: RootStoreModel;
  loading: boolean = false;

  constructor(rootStore: RootStoreModel) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
  }

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  getBuildingCost = (
    requirements: Requirements,
    buildingId: number,
    buildingLevel: number,
    planetResources: PlanetResources,
    typeBuildings: BuildingType,
    raceId: number,
    modificatory: number
  ) => {
    const buildingsCosts = () => {
      switch (typeBuildings) {
        case BuildingType.Enum.Building:
          return requirements.buildingsCost.find(
            buildingsCost =>
              buildingsCost.buildingId === buildingId && buildingsCost.buildingLevel === buildingLevel + 1
          );
        case BuildingType.Enum.Technology:
          return requirements.technologiesCost.find(
            technologies =>
              technologies.technologyId == buildingId && technologies.technologyLevel === buildingLevel + 1
          );
        case BuildingType.Enum.DefenceSystem:
          return requirements.defenseSystemsCost.find(defenseSystems => defenseSystems.defenseSystemId === buildingId);
        default:
          return requirements.shipComponentsCost.find(shipComponent => shipComponent.shipComponentId === buildingId);
      }
    };

    const buildingsCost = buildingsCosts();

    if (buildingsCost) {
      const resourceCost = (name: Resources) => {
        let costRes: number;
        if (DefenseOrShipComponentsCost.safeParse(buildingsCost).success && raceId) {
          const cost = DefenseOrShipComponentsCost.parse(buildingsCost);
          switch (raceId) {
            case RaceType.enum.Noberians:
              costRes = costRes = cost[`${name}LowCost`] * modificatory;
              break;
            case RaceType.enum.Bugserians:
              costRes = cost[`${name}HighCost`] * modificatory;
              break;
            default:
              costRes = cost[`${name}Cost`] * modificatory;
              break;
          }
        } else {
          costRes = buildingsCost[`${name}Cost`] * modificatory;
        }
        return Math.round(costRes);
      };
      const goldCost = resourceCost(Resources.Enum.gold);
      const crystalCost = resourceCost(Resources.Enum.crystal);
      const frubinCost = resourceCost(Resources.Enum.frubin);
      const frurozinCost = resourceCost(Resources.Enum.frurozin);
      const metalCost = resourceCost(Resources.Enum.metal);
      const orizinCost = resourceCost(Resources.Enum.orizin);

      const { metal, crystal, orizin, frubin, frurozin, gold } = planetResources;
      const isMetalEnough = metal ? metal?.metalAmount >= metalCost : false;
      const isCrystalEnough = crystal ? crystal?.crystalAmount >= crystalCost : false;
      const isOrizinEnough = orizin ? orizin?.orizinAmount >= orizinCost : false;
      const isFrubinEnough = frubin ? frubin?.frubinAmount >= frubinCost : false;
      const isFrurozinEnough = frurozin ? frurozin?.frurozinAmount >= frurozinCost : false;
      const isGoldEnough = gold ? gold?.goldAmount >= goldCost : false;
      const isEnoughResources =
        isMetalEnough && isCrystalEnough && isOrizinEnough && isFrubinEnough && isFrurozinEnough && isGoldEnough;
      return {
        isEnoughResources,
        ...{ ...buildingsCost, goldCost, crystalCost, frubinCost, frurozinCost, metalCost, orizinCost },
        isMetalEnough,
        isCrystalEnough,
        isOrizinEnough,
        isFrubinEnough,
        isFrurozinEnough,
        isGoldEnough
      };
    } else {
      return defaultReturn(typeBuildings);
    }
  };

  getBuildingRequirement = (
    requirements: Requirements,
    buildingId: number,
    buildings: Building[],
    technologies: Technology[],
    typeBuildings: BuildingType
  ) => {
    const buildingRequirements = () => {
      switch (typeBuildings) {
        case BuildingType.Enum.Building:
          return requirements.buildingsRequirements.filter(
            buildingRequirements => buildingRequirements.buildingId === buildingId
          );
        case BuildingType.Enum.Technology:
          return requirements.technologiesRequirements.filter(
            technologiesRequirements => technologiesRequirements.technologyId === buildingId
          );
        case BuildingType.Enum.DefenceSystem:
          return requirements.defenseSystemsRequirements.filter(
            defenseSystemsRequirements => defenseSystemsRequirements.defenseSystemId === buildingId
          );
        default:
          return requirements.shipComponentsRequirements.filter(
            shipComponent => shipComponent.shipComponentId == buildingId
          );
      }
    };

    const defaultReturn = {
      isEnoughBuilding: false,
      ...EMPTY_TECHNOLOGIES_REQUIREMENTS,
      ...EMPTY_BUILDINGS_REQUIREMENTS,
      ...EMPTY_DEFENSE_SYSTEMS_REQUIREMENTS,
      requiredBuilding: { ...EMPTY_BUILDING, ...EMPTY_TECHNOLOGY }
    };
    const finalDefault = { isEnoughBuilding: false, requiredBuilding: [defaultReturn] };
    const buildingRequirement = buildingRequirements();

    if (buildingRequirement) {
      const build = buildingRequirement.map(buildingReq => {
        const { requirementId, requirementLevel, requirementType } = buildingReq;

        const requiredBuilding =
          RequirementType.enum.Building === requirementType
            ? buildings.find(building => building.buildingId === requirementId)
            : technologies.find(technologies => technologies.technologyId === requirementId);

        if (requiredBuilding) {
          const isEnoughBuilding = isBuilding(requiredBuilding)
            ? requiredBuilding.buildingLevel >= requirementLevel
            : requiredBuilding.technologyValue >= requirementLevel;
          return { isEnoughBuilding, ...buildingReq, requiredBuilding };
        } else {
          return defaultReturn;
        }
      });
      const isEnough = build.find(req => !req.isEnoughBuilding);
      return { isEnoughBuilding: !isEnough, requiredBuilding: build };
    } else {
      return finalDefault;
    }
  };

  startBuilding = async (
    buildingId: number,
    planetId: number,
    mutate: KeyedMutator<GameSummaryProgress>,
    mutateBuilding: KeyedMutator<GameSummaryBuildings>
  ) => {
    try {
      this.setLoading(true);
      const response = await postData("Buildings/StartBuilding", {
        buildingId,
        planetId
      });
      mutate();
      mutateBuilding();
      console.log("startBuilding", response);
      this.setLoading(false);
      //@ts-ignore
      return response.data > 0;
    } catch (error) {
      mutate();
      mutateBuilding();
      this.setLoading(false);
    }
  };

  onFinishBuilding = async (
    buildingProgressId: number,
    planetId: number,
    mutate: KeyedMutator<GameSummaryProgress>,
    mutateBuilding: KeyedMutator<GameSummaryBuildings>
  ) => {
    try {
      this.setLoading(true);
      const response = await postData("Buildings/FinishBuilding", {
        buildingProgressId,
        planetId
      });
      console.log("onFinishBuilding", response);
      mutate();
      mutateBuilding();
      this.setLoading(false);
      //@ts-ignore
      return response.data > 0;
    } catch (error) {
      console.error("onFinishBuilding", error);
      mutate();
      mutateBuilding();
      this.setLoading(false);
    }
  };

  cancelBuilding = async (
    buildingProgressId: number,
    planetId: number,
    mutate: KeyedMutator<GameSummaryProgress>,
    mutateBuilding: KeyedMutator<GameSummaryBuildings>
  ) => {
    try {
      this.setLoading(true);
      const response = await postData("Buildings/CancelBuilding", { buildingProgressId, planetId });
      mutate();
      mutateBuilding();
      this.setLoading(false);
      //@ts-ignore
      return response.data > 0;
    } catch (error) {
      console.error("cancelBuilding", error);
      mutate();
      mutateBuilding();
      this.setLoading(false);
    }
  };
}
