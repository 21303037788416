import { useEffect } from "react";
import useSWR from "swr";
import { DefenseSystemsUpgrade } from "../types";
export function useDefenseSystemsUpdate(planetId: number) {
  const {
    data: defenseSystemsUpgrade,
    mutate: mutateDefenseSystemsUpgrade,
    error: errorDefenseSystemsUpgrade,
    isValidating: isValidatingDefenseSystemsUpgrade
  } = useSWR<DefenseSystemsUpgrade[]>(planetId ? `/DefenseSystems/GetDefenseSystemsUpgrade/${planetId}` : null);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [defenseSystemsUpgrade]);

  return {
    defenseSystemsUpgrade,
    mutateDefenseSystemsUpgrade,
    errorDefenseSystemsUpgrade,
    isValidatingDefenseSystemsUpgrade
  };
}

export default useDefenseSystemsUpdate;
