import { useEffect } from "react";
import useSWR from "swr";
import { FactionBonus } from "../types";

export function useFactionBonuses({ redirectTo = "", redirectIfFound = false } = {}) {
  const {
    data: factionBonuses,
    mutate: mutateFactionBonuses,
    error: errorFactionBonuses,
    isValidating: isValidatingFactionBonuses
  } = useSWR<FactionBonus[]>("/Faction/GetFactionBonuses");

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [factionBonuses, redirectIfFound, redirectTo]);

  return { factionBonuses, mutateFactionBonuses, errorFactionBonuses, isValidatingFactionBonuses };
}

export default useFactionBonuses;
