import { z } from "zod";
import { Progress } from "./common";
import { PlanetEnergyStats } from "./planet";
import { CostResource } from "./resources";

export type DefenseSystems = {
  defenseSystemBaseValues: DefenseSystemBase[];
  defenseSystemsUnits: DefenseSystemsUnits[];
  defenseSystemsDetails: DefenseStatDetailed[];
};

export const DefenseSystemsUpgrade = z.object({
  id: z.number(),
  userId: z.number(),
  defenseSystemId: z.number(),
  defenseSystemAttack: z.number(),
  defenseSystemDefense: z.number(),
  attackFinal: z.number(),
  defenseFinal: z.number(),
  attackModify: z.number(),
  defenseModify: z.number(),
  amount: z.number(),
  buildTime: z.number()
});

export const DefenseSystemsRecycle = CostResource.extend({
  id: z.number(),
  userId: z.number(),
  defenseSystemId: z.number(),
  defenseSystemAttack: z.number(),
  defenseSystemDefense: z.number(),
  amount: z.number()
});

export const DefenseSystemBase = z.object({
  userId: z.number(),
  defenseSystemId: z.number(),
  attack: z.number(),
  defense: z.number(),
  buildTime: z.number()
});

export const DefenseSystemsUnits = z.object({
  id: z.number(),
  userId: z.number(),
  defenseSystemId: z.number(),
  attack: z.number(),
  defense: z.number(),
  amount: z.number()
});

export const DefenseProgress = Progress.extend({ defenseSystemId: z.number(), defenseValue: z.number() });

export const DefenseStatsMain = z.object({
  planetId: z.number(),
  attackSum: z.number(),
  defenseSum: z.number()
});

export const DefenseStatDetailed = DefenseStatsMain.extend({
  defenseSystemId: z.number(),
  amount: z.number()
});

export const DefenseStatMain = z.object({ planetId: z.number(), attackSum: z.number(), defenseSum: z.number() });

export const DefenseStatAdditional = z.object({
  mineFieldAmount: z.number(),
  spaceAmount: z.number(),
  mineFieldPercentage: z.number(),
  shieldGeneratorAmount: z.number(),
  defenseSystemsAmount: z.number(),
  shieldGeneratorPercentage: z.number(),
  additionalDefenseSum: z.number(),
  percentChange: z.number(),
  isIonShield: z.boolean()
});

export const DefenseSystemStat = z.object({
  planetId: z.number(),
  planetEnergyStats: PlanetEnergyStats,
  defenseStatMain: DefenseStatMain,
  defenseStatAdditional: DefenseStatAdditional
});

export type DefenseProgress = z.infer<typeof DefenseProgress>;
export type DefenseSystemsUnits = z.infer<typeof DefenseSystemsUnits>;
export type DefenseSystemBase = z.infer<typeof DefenseSystemBase>;
export type DefenseSystemsUpgrade = z.infer<typeof DefenseSystemsUpgrade>;
export type DefenseSystemsRecycle = z.infer<typeof DefenseSystemsRecycle>;
export type DefenseStatsMain = z.infer<typeof DefenseStatsMain>;
export type DefenseStatDetailed = z.infer<typeof DefenseStatDetailed>;
export type DefenseStatMain = z.infer<typeof DefenseStatMain>;
export type DefenseStatAdditional = z.infer<typeof DefenseStatAdditional>;
export type DefenseSystemStat = z.infer<typeof DefenseSystemStat>;
