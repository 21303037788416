import { z } from "zod";

export const StorageItem = z.object({
  buildingLevel: z.number(),
  capacity: z.number()
});

export type StorageItem = z.infer<typeof StorageItem>;

export const StorageList = z.object({
  metal: z.array(StorageItem),
  crystal: z.array(StorageItem),
  frubin: z.array(StorageItem),
  orizin: z.array(StorageItem),
  frurozin: z.array(StorageItem),
  gold: z.array(StorageItem)
});

export type StorageList = z.infer<typeof StorageList>;

export const ProductionItem = z.object({ buildingLevel: z.number(), production: z.number(), bonusValue: z.number() });

export type ProductionItem = z.infer<typeof ProductionItem>;

export const ProductionList = z.object({
  metal: z.array(ProductionItem),
  crystal: z.array(ProductionItem),
  frubin: z.array(ProductionItem),
  orizin: z.array(ProductionItem),
  frurozin: z.array(ProductionItem),
  gold: z.array(ProductionItem)
});

export type ProductionList = z.infer<typeof ProductionList>;
