import { z } from "zod";
import { Building, BuildingProgress } from "./building";
import { DefenseProgress, DefenseStatAdditional, DefenseStatMain, DefenseStatsMain } from "./defence";
import { UserPlanet } from "./planet";
import { PlanetResources } from "./resources";
import { ShipsStatsMain } from "./ship";
import { ShipsProgress, UserSchema } from "./shipComponents";
import { Technology, TechnologyProgress } from "./technology";

export const GameSummary = z.object({
  buildings: z.array(Building),
  planetResources: PlanetResources,
  planet: UserPlanet,
  buildingsProgresses: z.array(BuildingProgress),
  technologies: z.array(Technology),
  technologiesProgress: TechnologyProgress.optional(),
  defenseProgress: z.array(DefenseProgress),
  defenseStat: DefenseStatsMain,
  shipsProgress: z.array(ShipsProgress)
});

export const GameSummaryProgress = GameSummary.pick({
  buildingsProgresses: true,
  technologiesProgress: true,
  defenseProgress: true,
  defenseStat: true,
  planet: true,
  planetResources: true,
  shipsProgress: true
});

export const GameSummaryBuildings = GameSummary.pick({
  buildings: true
});

export const GameSummaryTechnologies = GameSummary.pick({
  technologies: true
});

export const GameSummaryDefense = GameSummary.pick({});

export const GameSummarySchema = GameSummary.pick({}).extend({ schema: UserSchema });

export const PlanetStats = z.object({
  planetId: z.number(),
  defenseStatMain: DefenseStatMain,
  defenseStatAdditional: DefenseStatAdditional,
  shipsStatsMain: ShipsStatsMain
});
export type PlanetStats = z.infer<typeof PlanetStats>;

export const Dashboard = z.object({
  planetData: UserPlanet,
  buildingsProgresses: z.array(BuildingProgress),
  defenseProgress: z.array(DefenseProgress),
  planetResources: PlanetResources,
  planetStats: PlanetStats,
  shipsProgress: z.array(ShipsProgress),
  technologiesProgress: TechnologyProgress.optional()
});

export const ProgressBuildings = Dashboard.pick({
  buildingsProgresses: true,
  defenseProgress: true,
  shipsProgress: true,
  technologiesProgress: true
});

export const DashboardPlanets = z.object({
  planets: z.array(UserPlanet)
});

export const DashboardPlanet = z.object({
  planets: z.array(Dashboard)
});

export type GameSummary = z.infer<typeof GameSummary>;
export type GameSummaryBuildings = z.infer<typeof GameSummaryBuildings>;
export type GameSummaryTechnologies = z.infer<typeof GameSummaryTechnologies>;
export type GameSummaryDefense = z.infer<typeof GameSummaryDefense>;
export type GameSummarySchema = z.infer<typeof GameSummarySchema>;
export type Dashboard = z.infer<typeof Dashboard>;
export type DashboardPlanets = z.infer<typeof DashboardPlanets>;
export type GameSummaryProgress = z.infer<typeof GameSummaryProgress>;
export type ProgressBuildings = z.infer<typeof ProgressBuildings>;
export type DashboardPlanet = z.infer<typeof DashboardPlanet>;
