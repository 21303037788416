import { CommunicationCategoryEnum, CommunicationTabList, postData, toast } from "@new-wars/core";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { i18n } from "next-i18next";
import { KeyedMutator } from "swr";
import { RootStoreModel } from "./RootStore";

export class CommunicationStore {
  private rootStore: RootStoreModel;
  loading: boolean = false;
  newMessage: string = "";
  newMessageTitle: string = "";
  isOpenNewMessageModal: boolean = false;

  constructor(rootStore: RootStoreModel) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;

    makePersistable(this, {
      name: "CommunicationStore",
      properties: []
    });
  }

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  setNewMessageModalOpen = (open: boolean) => {
    this.isOpenNewMessageModal = open;
  };

  setNewMessage = (message: string) => {
    this.newMessage = message;
  };

  setNewTitle = (title: string) => {
    this.newMessageTitle = title;
  };

  sendMessage = async (toUserId: number, mutate: KeyedMutator<CommunicationTabList>) => {
    try {
      console.log("sendMessage", { toUserId });
      this.setLoading(true);
      const response = await postData("AddCommunicationElement", {
        title: this.newMessageTitle,
        content: this.newMessage,
        toUserId
      });
      console.log("sendMessage", { response });
      mutate();
      this.setNewMessage("");
      this.setNewTitle("");
      this.setLoading(false);
    } catch (error) {
      console.error("sendMessage", error);
      this.setLoading(false);
    }
  };

  archiveMessage = async (
    id: string,
    category: CommunicationCategoryEnum,
    mutate: KeyedMutator<CommunicationTabList>
  ) => {
    try {
      this.setLoading(true);
      const response = await postData("ArchiveCommunicationElement", { id, category });
      toast({
        title: response
          ? `${i18n?.t("startedResearch", { ns: "common" })}: ${name}`
          : i18n?.t("somethingWentWrong", { ns: "common" }),
        status: response ? "success" : "error",
        isClosable: true
      });
      mutate();
      this.setLoading(false);
    } catch (error) {
      console.error("sendMessage", error);
      this.setLoading(false);
    }
  };

  deleteMessage = async (
    id: string,
    category: CommunicationCategoryEnum,
    mutate: KeyedMutator<CommunicationTabList>
  ) => {
    try {
      this.setLoading(true);
      const response = await postData("DeleteCommunicationElement", { id, category });
      mutate();
      toast({
        title: response
          ? `${i18n?.t("deletedMessage", { ns: "common" })}`
          : i18n?.t("somethingWentWrong", { ns: "common" }),
        status: response ? "success" : "error",
        isClosable: true
      });
      this.setLoading(false);
    } catch (error) {
      this.setLoading(false);
      console.error("sendMessage", error);
    }
  };

  resetModal = () => {
    this.loading = false;
    this.newMessage = "";
    this.newMessageTitle = "";
    this.isOpenNewMessageModal = false;
  };
}
