import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";
import { Requirements } from "../types";

export function useAllRequirements({ redirectTo = "", redirectIfFound = false } = {}) {
  const {
    data: requirements,
    mutate: mutateRequirements,
    error: errorRequirements,
    isValidating: isValidatingRequirements
  } = useSWRImmutable<Requirements>("/GetAllRequirements");

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [requirements, redirectIfFound, redirectTo]);

  return { requirements, mutateRequirements, errorRequirements, isValidatingRequirements };
}

export default useAllRequirements;
