import { isBrowser } from "framer-motion";
import localforage from "localforage";
import { configure } from "mobx";
import { configurePersistable } from "mobx-persist-store";
import { enableStaticRendering } from "mobx-react";
import { BuildingStore } from "./BuildingStore";
import { CommunicationStore } from "./CommunicationStore";
import { DefenceStore } from "./DefenceStore";
import { FleetStore } from "./FleetStore";
import { ShipStore } from "./ShipStore";
import { TechnologyStore } from "./TechnologyStore";
import { UserStore } from "./UserStore";

enableStaticRendering(!isBrowser);
// https://mobx.js.org/configuration.html#configuration-
configure({
  enforceActions: "always",
  computedRequiresReaction: isBrowser,
  reactionRequiresObservable: isBrowser,
  observableRequiresReaction: isBrowser,
  disableErrorBoundaries: false
});
configurePersistable(
  {
    storage: isBrowser ? localforage : undefined,
    expireIn: 86400000,
    stringify: false,
    debugMode: true
  },
  { delay: 200, fireImmediately: false }
);

export type RootStoreModel = {
  userStore: UserStore;
  buildingStore: BuildingStore;
  defenceStore: DefenceStore;
  technologyStore: TechnologyStore;
  shipStore: ShipStore;
  fleetStore: FleetStore;
  communicationStore: CommunicationStore;
};

export class RootStore {
  userStore: UserStore;
  buildingStore: BuildingStore;
  defenceStore: DefenceStore;
  technologyStore: TechnologyStore;
  shipStore: ShipStore;
  fleetStore: FleetStore;
  communicationStore: CommunicationStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.buildingStore = new BuildingStore(this);
    this.technologyStore = new TechnologyStore(this);
    this.defenceStore = new DefenceStore(this);
    this.shipStore = new ShipStore(this);
    this.fleetStore = new FleetStore(this);
    this.communicationStore = new CommunicationStore(this);
  }
}
