import { useEffect } from "react";
import useSWR from "swr";
import { UserListApi } from "../types/user";

export function useUserList({ redirectTo = "", redirectIfFound = false } = {}) {
  const {
    data: userList,
    mutate: mutateUserList,
    error: errorUserList,
    isValidating: isValidatingUserList,
    isLoading: isLoadingUserList
  } = useSWR<UserListApi>("/Users/List");
  //TODO with userList
  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if userList data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo || !userList) return;

    // if (
    //   // If redirectTo is set, redirect if the userList was not found.
    //   (redirectTo && !redirectIfFound && !userList?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the userList was found
    //   (redirectIfFound && userList?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [userList, redirectIfFound, redirectTo]);

  return { userList, mutateUserList, errorUserList, isValidatingUserList, isLoadingUserList };
}

export default useUserList;
