import { IconProps } from "../../types";

export const AtomIcon = (props: IconProps) => (
  <svg
    className={props.className}
    width={props.width ?? 70}
    height={props.height ?? 70}
    viewBox="0 0 71 70"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2884 34.1517C14.4212 28.9784 11.3744 24.0166 12.9592 21.2969C14.2698 19.045 18.4856 18.7969 24.095 20.2255"
      className="icon-stroke"
      strokeWidth="2.03386"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M58.8926 44.7278C59.2741 45.9912 59.2424 47.0903 58.7356 47.9616C56.613 51.6063 46.8855 50.0069 35.9531 44.5342"
      className="icon-stroke"
      strokeWidth="2.03386"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M40.4805 27.3015C46.5601 30.8738 51.6154 34.8919 54.9241 38.5479"
      className="icon-stroke"
      strokeWidth="2.03386"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.8467 39.2478C19.2058 35.3331 24.7827 30.9188 31.592 27.0813C44.3378 19.8994 56.5743 17.4555 58.9238 21.6241C61.2741 25.7935 52.8442 34.9938 40.0992 42.1758C27.3535 49.3577 15.117 51.8016 12.7683 47.6322C12.3883 46.9602 12.2899 46.1556 12.4461 45.2485"
      className="icon-stroke"
      strokeWidth="2.03386"
      strokeMiterlimit="10"
    />
    <path
      d="M32.9961 9.54359C33.8666 8.63568 34.8013 8.14186 35.7752 8.13942C38.4468 8.13209 40.8452 11.8231 42.4511 17.6302"
      className="icon-stroke"
      strokeWidth="2.03386"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M42.4638 51.8844C40.8896 57.5312 38.5466 61.11 35.9205 61.1181C31.1344 61.1319 27.2237 49.2819 27.1839 34.6511C27.1652 27.3976 28.0999 20.8225 29.6318 16.0356"
      className="icon-stroke"
      strokeWidth="2.03386"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M30.9475 16.183C32.8579 16.183 34.4066 14.6343 34.4066 12.7238C34.4066 10.8134 32.8579 9.26465 30.9475 9.26465C29.037 9.26465 27.4883 10.8134 27.4883 12.7238C27.4883 14.6343 29.037 16.183 30.9475 16.183Z"
      className="icon-stroke"
      strokeWidth="2.03386"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M13.7639 45.5983C15.6743 45.5983 17.2231 44.0496 17.2231 42.1391C17.2231 40.2287 15.6743 38.6799 13.7639 38.6799C11.8534 38.6799 10.3047 40.2287 10.3047 42.1391C10.3047 44.0496 11.8534 45.5983 13.7639 45.5983Z"
      className="icon-stroke"
      strokeWidth="2.03386"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M57.2053 45.0109C59.1157 45.0109 60.6645 43.4622 60.6645 41.5517C60.6645 39.6413 59.1157 38.0925 57.2053 38.0925C55.2948 38.0925 53.7461 39.6413 53.7461 41.5517C53.7461 43.4622 55.2948 45.0109 57.2053 45.0109Z"
      className="icon-stroke"
      strokeWidth="2.03386"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M37.5214 34.6307C37.5212 34.9603 37.4233 35.2826 37.24 35.5566C37.0567 35.8307 36.7963 36.0442 36.4916 36.1702C36.187 36.2962 35.8518 36.3291 35.5284 36.2646C35.2051 36.2001 34.9082 36.0412 34.6752 35.808C34.4422 35.5747 34.2835 35.2776 34.2194 34.9542C34.1552 34.6309 34.1884 34.2957 34.3147 33.9912C34.441 33.6866 34.6548 33.4264 34.929 33.2434C35.2032 33.0603 35.5256 32.9627 35.8553 32.9629C36.7786 32.9629 37.5214 33.7065 37.5214 34.6307Z"
      className="icon-stroke icon-fill"
      strokeWidth="2.03386"
      strokeMiterlimit="10"
    />
  </svg>
);
