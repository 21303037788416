import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";
import { Description } from "../types";

export function useRaceDescription({ redirectTo = "", redirectIfFound = false } = {}) {
  const {
    data: raceDesc,
    mutate: mutateRaceDesc,
    error: errorRaceDesc,
    isValidating: isValidatingRaceDesc
  } = useSWRImmutable<Description[]>("/Race/GetAllRaceDescriptions");

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [raceDesc, redirectIfFound, redirectTo]);

  return { raceDesc, mutateRaceDesc, errorRaceDesc, isValidatingRaceDesc };
}

export default useRaceDescription;
