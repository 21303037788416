import { GameSummaryProgress, GameSummaryTechnologies, postData, toast } from "@new-wars/core";
import { makeAutoObservable } from "mobx";
import { i18n } from "next-i18next";
import { KeyedMutator } from "swr";
import { RootStoreModel } from "./RootStore";
export class TechnologyStore {
  private rootStore: RootStoreModel;
  loading: boolean = false;

  constructor(rootStore: RootStoreModel) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
  }

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  startTechnology = async (
    technologyId: number,
    planetId: number,
    name: string,
    mutate: KeyedMutator<GameSummaryProgress>
  ) => {
    try {
      this.setLoading(true);
      const response = await postData("Technologies/StartDiscover", {
        technologyId,
        planetId
      });
      mutate();
      toast({
        title: response
          ? `${i18n?.t("startedResearch", { ns: "common" })}: ${name}`
          : i18n?.t("somethingWentWrong", { ns: "common" }),
        status: response ? "success" : "error",
        isClosable: true
      });
      this.setLoading(false);
      console.log("startTechnology", response);
      //@ts-ignore
      return response.data > 0;
    } catch (error) {
      console.error("startTechnology", error);
      this.setLoading(false);
    }
  };

  cancelTechnology = async (
    technologyProgressId: number,
    planetId: number,
    mutate: KeyedMutator<GameSummaryProgress>,
    mutateTable: KeyedMutator<GameSummaryTechnologies>
  ) => {
    try {
      this.setLoading(true);
      const response = await postData("Technologies/CancelDiscover", { technologyProgressId, planetId });
      mutate();
      mutateTable();
      toast({
        title: response
          ? `${i18n?.t("canceled", { ns: "common" })}: ${name}`
          : i18n?.t("somethingWentWrong", { ns: "common" }),
        status: response ? "success" : "error",
        isClosable: true
      });
      this.setLoading(false);
      //@ts-ignore
      return response.data > 0;
    } catch (error) {
      console.error("cancelTechnology", error);
      this.setLoading(false);
    }
  };

  onFinishTechnology = async (
    technologyProgressId: number,
    planetId: number,
    mutate: KeyedMutator<GameSummaryProgress>,
    mutateTable: KeyedMutator<GameSummaryTechnologies>
  ) => {
    try {
      this.setLoading(true);
      const response = await postData("Technologies/FinishDiscover", { technologyProgressId, planetId });
      console.log("onFinishTechnology", response);
      mutate();
      mutateTable();
      this.setLoading(false);
      //@ts-ignore
      return response.data > 0;
    } catch (error) {
      console.error("onFinishTechnology", error);
      this.setLoading(false);
    }
  };
}
