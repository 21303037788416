import { z } from "zod";

export const FactionForRace = z.object({
  raceId: z.number(),
  factionId: z.number()
});

export const FactionBonus = z.object({
  factionId: z.number(),
  bonusId: z.number(),
  bonusValue: z.number()
});

export type FactionForRace = z.infer<typeof FactionForRace>;
export type FactionBonus = z.infer<typeof FactionBonus>;
