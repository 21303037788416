import { ReactNode, useEffect } from "react";
import { useUserStore } from "../../hooks";
import { CustomSession } from "../../pages/api/auth/[...nextauth]";
type Props = {
  children?: ReactNode;
  session: CustomSession;
};

export const UserSettingsProvider = ({ children, session }: Props) => {
  const { setRace, setUserId } = useUserStore();
  useEffect(() => {
    setRace(session?.user?.raceId);
    setUserId(Number.parseInt(session?.id));
  }, [session?.user?.raceId]);
  return <>{children}</>;
};

export default UserSettingsProvider;
