import { useEffect } from "react";
import useSWR from "swr";
import { DiscoveredSystem } from "../types";

export function useDiscoveredSystems() {
  const {
    data: discoveredSystems,
    mutate: mutateDiscoveredSystems,
    error: errorDiscoveredSystems,
    isValidating: isValidatingDiscoveredSystems,
    isLoading: isLoadingDiscoveredSystems
  } = useSWR<DiscoveredSystem[]>(`/GetUserDiscoveredSystems`);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [discoveredSystems]);

  return {
    discoveredSystems,
    mutateDiscoveredSystems,
    errorDiscoveredSystems,
    isValidatingDiscoveredSystems,
    isLoadingDiscoveredSystems
  };
}

export default useDiscoveredSystems;
