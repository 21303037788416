import { useEffect } from "react";
import useSWRImmutable from "swr/immutable";
import { Description } from "../types";

export function useTechnologiesDescription({ redirectTo = "", redirectIfFound = false } = {}) {
  const {
    data,
    mutate: mutateTechnologiesDesc,
    error: errorTechnologiesDesc,
    isValidating: isValidatingTechnologiesDesc
  } = useSWRImmutable<Description[]>("/Technologies/GetAllTechnologiesDescriptions");
  const technologiesDesc = data ?? [];
  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [technologiesDesc, redirectIfFound, redirectTo]);

  return { technologiesDesc, mutateTechnologiesDesc, errorTechnologiesDesc, isValidatingTechnologiesDesc };
}

export default useTechnologiesDescription;
