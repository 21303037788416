import { useEffect } from "react";
import useSWR from "swr";
import { CommunicationCategoryEnum, CommunicationTabList } from "../types";

export function useCommunicationTabList(category: CommunicationCategoryEnum) {
  const {
    data: communicationTabList,
    mutate: mutateCommunicationTabList,
    error: errorCommunicationTabList,
    isValidating: isValidatingCommunicationTabList
  } = useSWR<CommunicationTabList>(
    category !== null || category !== undefined ? `/GetCommunicationTabList/${category}` : null
  );

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [communicationTabList]);

  return {
    communicationTabList,
    mutateCommunicationTabList,
    errorCommunicationTabList,
    isValidatingCommunicationTabList
  };
}

export default useCommunicationTabList;
