import { useContext } from "react";
import { BuildingStore } from "../store/BuildingStore";
import { CommunicationStore } from "../store/CommunicationStore";
import { DefenceStore } from "../store/DefenceStore";
import { FleetStore } from "../store/FleetStore";
import { RootStore } from "../store/RootStore";
import { ShipStore } from "../store/ShipStore";
import { StoreContext } from "../store/StoreProvider";
import { TechnologyStore } from "../store/TechnologyStore";
import { UserStore } from "../store/UserStore";

export const useStores = (): RootStore => useContext(StoreContext);
export const useUserStore = (): UserStore => useContext(StoreContext).userStore;
export const useTechnologyStore = (): TechnologyStore => useContext(StoreContext).technologyStore;
export const useBuildingStore = (): BuildingStore => useContext(StoreContext).buildingStore;
export const useDefenseStore = (): DefenceStore => useContext(StoreContext).defenceStore;
export const useShipStore = (): ShipStore => useContext(StoreContext).shipStore;
export const useFleetStore = (): FleetStore => useContext(StoreContext).fleetStore;
export const useCommunicationStore = (): CommunicationStore => useContext(StoreContext).communicationStore;
