import { useEffect } from "react";
import useSWR from "swr";
import { CommunicationShort } from "../types";

export function useCommunicationShort(isAuthenticated: boolean) {
  const {
    data: communicationShort,
    mutate: mutateCommunicationShort,
    error: errorCommunicationShort,
    isValidating: isValidatingCommunicationShort
  } = useSWR<CommunicationShort>(isAuthenticated ? `/GetCommunicationShort` : null);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) return;
    // if (
    //   // If redirectTo is set, redirect if the user was not found.
    //   (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
    //   // If redirectIfFound is also set, redirect if the user was found
    //   (redirectIfFound && user?.isLoggedIn)
    // ) {
    //   Router.push(redirectTo);
    // }
  }, [communicationShort]);

  return { communicationShort, mutateCommunicationShort, errorCommunicationShort, isValidatingCommunicationShort };
}

export default useCommunicationShort;
