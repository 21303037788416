import { ChakraProvider } from "@chakra-ui/react";
import { ToastContainer, registerAxiosResponseInterceptor, setAxiosClientAuthHeaders } from "@new-wars/core";
import axiosClient, { setAxiosClientLanguage } from "@new-wars/core/src/axios/axiosClient";
import { AxiosRequestConfig } from "axios";
import { setDefaultOptions } from "date-fns";
import { de, enGB, pl } from "date-fns/locale";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { SessionProvider } from "next-auth/react";
import { SSRConfig, appWithTranslation } from "next-i18next";
import type { AppProps as NextJsAppProps } from "next/app";
import { createSignalRContext } from "react-signalr";
import { SWRConfig } from "swr";
import UserSettingsProvider from "../components/UserSettingsProvider/UserSettingsProvider";
import nextI18nextConfig from "../next-i18next.config";
import { StoreProvider } from "../store/StoreProvider";
import "../styles/globals.css";
import theme from "../styles/theme";
import { CustomSession } from "./api/auth/[...nextauth]";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

const fetcher = async (args: null | string | [string | null, AxiosRequestConfig]) => {
  const [url, cfg] = Array.isArray(args) ? args : [args];
  if (!url) return Promise.resolve(undefined);
  return axiosClient.request<unknown>({ ...cfg, url }).then(res => res.data);
};

const SignalRContext = createSignalRContext();

type PageProps = SSRConfig & {
  session: CustomSession;
  fallback?: {
    [key: string]: any;
  };
};
declare type AppProps = NextJsAppProps & {
  pageProps: PageProps;
};

function MyApp(appProps: AppProps) {
  const { Component, pageProps } = appProps;
  // console.log("FALLBACK", pageProps.fallback);
  const token = pageProps?.session?.jwt;
  registerAxiosResponseInterceptor();
  const initialLocale = pageProps?._nextI18Next?.initialLocale ?? "pl";

  setDefaultOptions({ locale: initialLocale === "en" ? enGB : initialLocale === "de" ? de : pl });
  setAxiosClientAuthHeaders(token);
  setAxiosClientLanguage(initialLocale);
  return (
    <StoreProvider>
      {/* <SignalRContext.Provider
        connectEnabled={!!token}
        accessTokenFactory={() => token}
        dependencies={[token]} //remove previous connection and create a new connection if changed
        url={process.env.NEXT_PUBLIC_BACKEND_URL ?? ""}
      > */}
      <ChakraProvider theme={theme}>
        <SessionProvider session={pageProps?.session} refetchInterval={0}>
          <UserSettingsProvider session={pageProps?.session}>
            <SWRConfig
              value={{
                fetcher: fetcher,
                onError: err => {
                  console.error("SWR ERROR: ", err);
                },
                fallback: { ...pageProps?.fallback }
              }}
            >
              <Component {...pageProps} />
              <ToastContainer />
            </SWRConfig>
          </UserSettingsProvider>
        </SessionProvider>
      </ChakraProvider>
      {/* </SignalRContext.Provider> */}
    </StoreProvider>
  );
}

export default appWithTranslation(MyApp, {
  ...nextI18nextConfig
});
